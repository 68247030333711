<template>
  <div>
    <div class="d-flex justify-space-between mx-3 mt-4">
      <div @click="handleInput(0)" class="box-stepper">
        <div class="stepper activated-dot"></div>
        <p>ใบเสนอราคา</p>
      </div>
      <hr :style="{ borderColor: stepNumber >= 1 ? '#6E7F94' : '#e0e0e0' }" />
      <div @click="handleInput(1)" class="box-stepper">
        <div
          class="stepper"
          :style="{ backgroundColor: stepNumber >= 1 ? '#6E7F94' : '#e0e0e0' }"
        ></div>
        <p>รับออเดอร์</p>
      </div>
      <hr :style="{ borderColor: stepNumber >= 2 ? '#6E7F94' : '#e0e0e0' }" />
      <div @click="handleInput(2)" class="box-stepper">
        <div
          class="stepper"
          :style="{ backgroundColor: stepNumber >= 2 ? '#6E7F94' : '#e0e0e0' }"
        ></div>
        <p>ดำเนินการ</p>
      </div>
      <hr :style="{ borderColor: stepNumber >= 3 ? '#6E7F94' : '#e0e0e0' }" />
      <div @click="handleInput(3)" class="box-stepper">
        <div
          class="stepper"
          :style="{ backgroundColor: stepNumber >= 3 ? '#6E7F94' : '#e0e0e0' }"
        ></div>
        <p>รับสินค้าแล้ว</p>
      </div>
    </div>

    <!--    step 2 dialog : ask to change status-->
    <v-dialog v-model="showConfirmChangeStatus" max-width="500px" persistent>
      <v-card>
        <v-card-title>
          <span>เปลี่ยนเป็นรับ Order</span>
          <v-spacer></v-spacer>
        </v-card-title>
        <v-card-text> คุณยืนยันที่รับออเดอร์? </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            text
            @click="
              showConfirmChangeStatus = false;
              $emit('input', stepText[currentIndex]);
            "
          >
            ยังไม่ใช่ตอนนี้
          </v-btn>
          <v-btn
            color="primary"
            @click="
              showConfirmChangeStatus = false;
              changeToOrder();
            "
          >
            เปลี่ยนเป็นรับออเดอร์
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!--    step 3 dialog : select stock-->
    <v-dialog
      v-if="!forCreate"
      v-model="showSelectStock"
      max-width="648px"
      persistent
    >
      <v-stepper v-if="productStock.length > 0" v-model="stepper">
        <v-stepper-items>
          <v-stepper-content
            v-for="(item, index) in productStock"
            :key="item.id"
            :step="index + 1"
          >
            <v-card class="mb-4" height="280">
              <v-card-title
                class="px-0 pt-0 mb-4 d-flex justify-space-between"
                style="font-size: 1.75rem"
              >
                <span
                  >เลือก Stock ของ {{ item.item_name
                  }}<span class="orange--text mx-2"></span>
                </span>
                <v-icon
                  @click="
                    showSelectStock = false;
                    $emit('input', stepText[currentIndex]);
                  "
                  >mdi-close</v-icon
                >
              </v-card-title>
              <v-card-text class="px-0">
                <p style="font-size: 1.155rem">
                  จำนวน"{{ item.item_name }}"ที่เลือกไว้
                  <b class="red--text">{{ item.item_quantity }}</b>
                  {{ item.unit }}
                </p>
                <v-row v-for="(stock, index) in item.stock" :key="stock.id">
                  <v-col
                    >{{ stock.location }}
                    <span class="blue--text ml-2"
                      >(เหลือ {{ stock.amount }})</span
                    ></v-col
                  >
                  <v-col>
                    <v-text-field
                      :disabled="item.stock.length === 1"
                      :rules="[rules.min]"
                      v-model="stock.cut"
                      min="0"
                      outlined
                      hide-details
                      type="number"
                    >
                    </v-text-field>
                  </v-col>
                </v-row>
                <!--                {{ getStockProduct(item.product_id) }}-->
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn v-if="index + 1 > 1" text @click="stepper--">
                  ย้อนกลับ
                </v-btn>
                <v-btn
                  v-if="index + 1 < productStock.length"
                  color="primary"
                  @click="stepper++"
                >
                  ต่อไป
                </v-btn>
                <v-btn
                  v-else
                  color="primary"
                  @click="
                    showSelectStock = false;
                    changeToOperation();
                  "
                >
                  ดำเนินการ
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-stepper-content>
        </v-stepper-items>
      </v-stepper>
      <v-card v-else>
        <v-card-title> เปลี่ยนเป็นสถานะดำเนินการ </v-card-title>
        <v-card-text>
          รายการสินค้าที่อยู่ในออเดอร์จะไม่สามารถเปลี่ยนแปลงได้
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn @click="showSelectStock = false" text> ยังไม่ใช่ตอนนี้ </v-btn>
          <v-btn
            color="primary"
            @click="
              showSelectStock = false;
              changeToOperation();
            "
          >
            ดำเนินการ
          </v-btn>
        </v-card-actions>
      </v-card>

      <!--        <v-card-actions>-->
      <!--          <v-spacer></v-spacer>-->
      <!--          <v-btn-->
      <!--              text-->
      <!--              @click="showSelectStock = false;$emit('input', stepText[currentIndex])"-->
      <!--          >-->
      <!--            ยังไม่ใช่ตอนนี้-->
      <!--          </v-btn>-->
      <!--          <v-btn-->
      <!--              color="primary"-->
      <!--              @click="showSelectStock = false;changeToOperation()"-->
      <!--          >-->
      <!--            เปลี่ยนเป็นรับออเอดร์-->
      <!--          </v-btn>-->
      <!--        </v-card-actions>-->
    </v-dialog>

    <!--    step 4 dialog : change to complete this order-->
    <v-dialog v-model="showConfirmCompleteOrder" max-width="500px" persistent>
      <v-card>
        <v-card-title>
          <span>ออเดอร์นี้ได้รับสินค้าแล้ว</span>
          <v-spacer></v-spacer>
        </v-card-title>
        <v-card-text>
          โปรดตรวจสอบข้อมูลให้ถูกต้อง
          หากบันทึกข้อมูลไปแล้วจะไม่สามารถย้อนกลับขั้นตอนได้
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            text
            @click="
              showConfirmCompleteOrder = false;
              $emit('input', stepText[currentIndex]);
            "
          >
            ยังไม่ใช่ตอนนี้
          </v-btn>
          <v-btn
            color="primary"
            @click="
              showConfirmCompleteOrder = false;
              changeToComplete();
            "
          >
            ออเดอร์นี้ได้รับสินค้าเรียบร้อยแล้ว
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import axios from "axios";

export default {
  props: {
    value: String,
    forCreate: Boolean,
    order: Object,
  },
  name: "OrderStatusTimeline",
  data() {
    return {
      rules: {
        required: (value) => !!value || "ต้องกรอก",
        required_select: (value) => !!value || "ต้องเลือก",
        min: (value) => value > 0 || "จำนวนต้องเป็นตั้งแต่ 1 ขึ้นไป",
      },
      stepText: ["ใบเสนอราคา", "รับออเดอร์", "ดำเนินการ", "สำเร็จ"],
      showConfirmChangeStatus: false,
      showSelectStock: false,
      showConfirmCompleteOrder: false,
      currentIndex: 0,
      stepper: 1,
      // productStock: []
    };
  },
  // mounted() {
  //   console.log(this.order.item)
  //   // if (this.value === 'รับออเดอร์') {
  //   this.order.item.forEach(i => {
  //     console.log(i)
  //     if (i.product_id !== null) {
  //       this.productStock.push(i)
  //     }
  //   })
  //   // }
  // },
  methods: {
    handleInput(index) {
      this.currentIndex = this.stepNumber;
      if (this.forCreate && index < 2) {
        // if in create order. Can't select operation and complete
        this.$emit("input", this.stepText[index]);
      } else if (!this.forCreate) {
        if (index === this.stepNumber + 1) {
          if (index === 1) {
            this.showConfirmChangeStatus = true;
          } else if (index === 2) {
            this.showSelectStock = true;
          } else if (index === 3) {
            this.showConfirmCompleteOrder = true;
          }
          this.$emit("input", this.stepText[index]);
        }
      }
    },
    changeToOrder() {
      console.log("change to order");
      let payload = {
        id: this.$route.params.id,
        order_status: "รับออเดอร์",
      };
      axios
        .put("/api/orders/", payload)
        .then((res) => {
          console.log(res.data.data);
          this.$store.state.snackbar = {
            text: `ออเดอร์นี้สำเร็จแล้ว`,
            visible: true,
            color: "success",
          };
          window.location.reload();
        })
        .catch((e) => {
          console.error(e);
          this.$store.state.snackbar = {
            text:
              "เกิดข้อผิดพลาด กรุณาลองใหม่อีกครั้ง ข้อความที่ระบบแจ้ง : " +
              e.response.status +
              " " +
              e.response.statusText,
            visible: true,
            color: "error",
          };
          this.value = "ใบเสนอราคา";
        });
    },
    changeToOperation() {
      console.log("change to operation");
      if (this.productStock.length > 0) {
        let payload = [];
        this.productStock.forEach((orderItem) => {
          // console.log(product.stock)
          orderItem.stock.forEach((stock) => {
            if (stock.cut > 0) {
              payload.push({
                order_item_id: orderItem.id,
                stock_location_id: stock.id,
                amount: parseFloat(stock.cut),
              });
            }
          });
        });
        console.log(payload);
        axios
          .post("/api/orders/stock2order/", { data: payload })
          .then((res) => {
            console.log(res.data.data);
            this.$store.state.snackbar = {
              text: `ออเดอร์นี้อยู่ในขั้นตอนดำเนินการแล้ว`,
              visible: true,
              color: "success",
            };
          })
          .catch((e) => {
            console.error(e);
            this.$store.state.snackbar = {
              text: `ข้อผิดพลาด ของอาจจะไม่พอ`,
              visible: true,
              color: "error",
            };
            this.value = "รับออเดอร์";
          });
      } else {
        let payload = {
          id: this.$route.params.id,
          order_status: "ดำเนินการ",
        };
        axios
          .put("/api/orders/", payload)
          .then((res) => {
            console.log(res.data.data);
            this.$store.state.snackbar = {
              text: `ออเดอร์นี้อยู่ในขั้นตอนดำเนินการแล้ว`,
              visible: true,
              color: "success",
            };
          })
          .catch((e) => {
            console.error(e);
            this.$store.state.snackbar = {
              text:
                "เกิดข้อผิดพลาด กรุณาลองใหม่อีกครั้ง ข้อความที่ระบบแจ้ง : " +
                e.response.status +
                " " +
                e.response.statusText,
              visible: true,
              color: "error",
            };
            this.value = "รับออเดอร์";
          });
      }
    },
    changeToComplete() {
      console.log("change to complete");
      let payload = {
        id: this.$route.params.id,
        order_status: "สำเร็จ",
      };
      axios
        .put("/api/orders/", payload)
        .then((res) => {
          console.log(res.data.data);
          this.$store.state.snackbar = {
            text: `ออเดอร์นี้สำเร็จแล้ว`,
            visible: true,
            color: "success",
          };
        })
        .catch((e) => {
          console.error(e);
          this.$store.state.snackbar = {
            text: `ข้อผิดพลาด`,
            visible: true,
            color: "error",
          };
          this.value = "ดำเนินการ";
        });
    },
    // willCut() {
    //   this.productStock[this.stepper - 1].stock.forEach((s) => {
    //     let amount = s.cut
    //     if (amount) {
    //       console.log(s)
    //       this.stepper++
    //       // this.$store.commit('addPickInData', {
    //       //   po_item_id: this.item.id,
    //       //   stock_location_id: s.stock_location_id,
    //       //   amount: amount
    //       // })
    //     }
    //   })
    // },
  },
  computed: {
    stepNumber() {
      return this.stepText.indexOf(this.value);
    },
    productStock() {
      let productStock = [];
      this.order.item.forEach((i) => {
        console.log("i", i);
        if (i.product_id) {
          if (i.stock.length === 1) {
            i.stock[0]["cut"] = i.item_quantity;
          }
          productStock.push(i);
        }
      });
      return productStock;
    },
  },
};
</script>

<style scoped>
.box-stepper {
  text-align: -webkit-center;
  z-index: 99;
  cursor: pointer;
}

.stepper {
  width: 30px;
  height: 30px;
  /*background-color: #6E7F94;*/
  /*background-color: #e0e0e0;*/
  border-radius: 99px;
  border: 4px solid white;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  margin-bottom: 10px;
}

hr {
  border: 7px solid;
  /*border-color: #e0e0e0;*/
  display: block;
  -webkit-box-flex: 1;
  -ms-flex: 1 1 0;
  flex: 1 1 0;
  max-width: 100%;
  height: 0;
  transition: inherit;
  margin: 7.5px -35px 0;
  align-self: flex-start;
  z-index: 1;
}

.activated-hr {
  border-color: #6e7f94 !important;
}

.activated-dot {
  background-color: #6e7f94 !important;
}
</style>