<template>
  <div>
    <v-form ref="form"
            lazy-validation v-model="valid">
      <v-data-table hide-default-footer
                    disable-sort
                    :headers="headersCompute"
                    :items-per-page="25"
                    :items="orderItems.item">

        <template v-slot:item.item_name="{item}">
          {{ item.item_name }} {{
            (sellerId && item.product_id === null) ? '(ต้นทุน ' + item.cost + ' บาท)' : ''
          }}
        </template>

        <template v-slot:item.item_price_per_unit="{item}">
          <v-text-field
              :disabled="!(updateOrderProps.order_status ==='ใบเสนอราคา' || updateOrderProps.order_status ==='รับออเดอร์') || updateOrderProps.payment_status === 'ชำระแล้ว'"
              class="centered-input"
              type="number"
              v-model="item.item_price_per_unit"
              outlined hide-details height="32"
          ></v-text-field>
        </template>

        <template v-slot:item.item_quantity="{item}">
          <v-text-field
              :disabled="!(updateOrderProps.order_status ==='ใบเสนอราคา' || updateOrderProps.order_status ==='รับออเดอร์') || updateOrderProps.payment_status === 'ชำระแล้ว'"
              class="centered-input"
              v-model="item.item_quantity"
              type="number"
              :rules="[rules.min]"
              outlined hide-details height="32"
          >
          </v-text-field>
        </template>

        <template v-slot:item.item_total="{item}">
          {{ (item.item_price_per_unit * item.item_quantity) || 0 }}
        </template>

        <template v-slot:item.discount="{item}">
          <v-text-field
              :disabled="!(updateOrderProps.order_status ==='ใบเสนอราคา' || updateOrderProps.order_status ==='รับออเดอร์') || updateOrderProps.payment_status === 'ชำระแล้ว'"
              class="centered-input"
              v-model="item.discount"
              type="number"
              :rules="[rules.minZero]"
              outlined hide-details height="32"
          >
          </v-text-field>
        </template>

        <template v-slot:item.total="{item}">
          {{ ((item.item_price_per_unit * item.item_quantity) - item.discount) || 0 }}
        </template>

        <template v-slot:item.commission_rate="{item}">
          <span v-if="item.product_id ">
            {{ item.commission_rate ? item.commission_rate + '%' : '' }}
          </span>
          <span v-else>
            <v-text-field
                :disabled="!(updateOrderProps.order_status ==='ใบเสนอราคา' || updateOrderProps.order_status ==='รับออเดอร์') || updateOrderProps.payment_status === 'ชำระแล้ว'"
                class="centered-input"
                height="32px"
                hide-details number outlined
                v-model="item.commission_rate"
                type="number"
                :rules="[rules.minZero]"
                suffix="%">
            </v-text-field>
          </span>
        </template>

        <template
            v-slot:item.actions="{ item }">
          <v-btn @click="editOrderItem(item)" icon color="green"
                 :disabled="!updateOrderProps.isActive"
                 v-if="(updateOrderProps.order_status ==='ใบเสนอราคา' || updateOrderProps.order_status ==='รับออเดอร์' || updateOrderProps.order_status ==='ดำเนินการ') && !forCreate"
          >
            <v-icon>mdi-content-save</v-icon>
          </v-btn>
          <v-btn
              icon
              v-if="updateOrderProps.order_status ==='ใบเสนอราคา' || updateOrderProps.order_status ==='รับออเดอร์'"
              color="error"
              :disabled="(forCreate ? false : !updateOrderProps.isActive) || updateOrderProps.payment_status === 'ชำระแล้ว'"
              @click="willRemoveItem = item;showRemoveDialog = true"
          >
            <v-icon>mdi-delete</v-icon>
          </v-btn>
          <!--          <v-icon-->
          <!--              v-if="updateOrderProps.order_status ==='ใบเสนอราคา' || updateOrderProps.order_status ==='รับออเดอร์'"-->
          <!--              color="error"-->
          <!--              class="mr-2"-->
          <!--              @click="willRemoveItem = item;showRemoveDialog = true"-->
          <!--          >-->
          <!--            mdi-delete-->
          <!--          </v-icon>-->
        </template>

        <template v-if="typeAdd === 1" slot="body.append">
          <tr style="background-color: #fff8e9">
            <td>
              <v-select class="rounded mt-0"
                        :items="productSelect"
                        v-model="productAdd.product"
                        outlined
                        dense
                        height="32px"
                        hide-details
                        item-text="name"
                        return-object
                        placeholder="เลือกสินค้า"
                        @change="productAdd.price_per_unit = price($event)"
              >
              </v-select>
            </td>
            <!--            <td class="text-center">{{ price(productAdd.product) || '' }}</td>-->
            <td>
              <v-text-field
                  v-if="productAdd.product"
                  :value="price(productAdd.product)"
                  v-model="productAdd.price_per_unit"
                  outlined
                  height="32px"
                  type="number"
                  class="centered-input"
                  hide-details
              >
              </v-text-field>
            </td>
            <td>
              <v-text-field
                  class="centered-input"
                  v-model="productAdd.quantity"
                  outlined
                  placeholder="จำนวน"
                  height="32px"
                  type="number"
                  hide-details/>
            </td>
            <td class="text-center">
              {{ productAdd.price_per_unit * productAdd.quantity || '' }}
            </td>
            <td>
              <v-text-field
                  class="centered-input"
                  v-model="productAdd.discount"
                  outlined
                  placeholder="ส่วนลด"
                  height="32px"
                  type="number"
                  hide-details/>
            </td>
            <td class="text-center">
              {{ (productAdd.price_per_unit * productAdd.quantity) - productAdd.discount || '' }}
            </td>
            <td v-if="sellerId">
              <v-text-field outlined
                            v-model="productAdd.product.commission_rate"
                            disabled
                            class="centered-input"
                            height="32px"
                            hide-details
                            suffix="%"
                            type="number"/>
            </td>
            <td>
              <v-icon
                  :disabled="!(Object.keys(productAdd.product).length !== 0 && productAdd.quantity > 0 && productAdd.discount >= 0)"
                  color="success"
                  class="mr-2"
                  @click="addProduct"
              >
                mdi-plus-circle
              </v-icon>
              <v-icon
                  color="error"
                  class="mr-2"
                  @click="typeAdd = 0"
              >
                mdi-close-circle
              </v-icon>
            </td>
          </tr>
        </template>

        <template v-if="typeAdd === 2" slot="body.append">
          <tr style="background-color: #fff8e9">
            <td>
              <v-select class="rounded mt-0"
                        :items="giftProductSelect"
                        v-model="giftAdd.product"
                        outlined
                        dense
                        height="32px"
                        hide-details
                        item-text="name"
                        return-object
                        placeholder="เลือกสินค้า">
              </v-select>
            </td>
            <td class="text-center"></td>
            <td>
              <v-text-field
                  class="centered-input"
                  v-model="giftAdd.quantity"
                  outlined
                  placeholder="จำนวน"
                  height="32px"
                  type="number"
                  hide-details/>
            </td>
            <td class="text-center">
              {{ giftAdd.product.price * giftAdd.quantity || '' }}
            </td>
            <td>
            </td>
            <td class="text-center">
              {{ (giftAdd.product.price * giftAdd.quantity) || '' }}
            </td>
            <td v-if="sellerId"/>
            <td>
              <v-icon
                  @click="addGiftProduct"
                  color="success"
                  class="mr-2"
                  :disabled="!(Object.keys(giftAdd.product).length !== 0 && giftAdd.quantity >= 0)"
              >
                mdi-plus-circle
              </v-icon>
              <v-icon
                  color="error"
                  class="mr-2"
                  @click="typeAdd = 0"
              >
                mdi-close-circle
              </v-icon>
            </td>
          </tr>
        </template>

        <template v-if="typeAdd === 3" slot="body.append">
          <tr style="background-color: #fff8e9">
            <td>
              ส่วนลด
            </td>
            <td class="text-center"></td>
            <td>
            </td>
            <td class="text-center">
              <v-text-field
                  class="centered-input"
                  v-model="discountAdd.discount"
                  outlined
                  placeholder="จำนวน"
                  height="32px"
                  type="number"
                  hide-details/>
            </td>
            <td>
            </td>
            <td class="text-center">

            </td>
            <td v-if="sellerId"/>
            <td>
              <v-icon
                  color="success"
                  class="mr-2"
                  @click="addDiscount"
                  :disabled="discountAdd.discount <= 0"
              >
                mdi-plus-circle
              </v-icon>
              <v-icon
                  color="error"
                  class="mr-2"
                  @click="typeAdd = 0"
              >
                mdi-close-circle
              </v-icon>
            </td>
          </tr>
        </template>

        <template v-if="typeAdd === 4" slot="body.append">
          <tr style="background-color: #fff8e9">
            <td>
              <v-text-field
                  v-model="otherAdd.title"
                  outlined
                  placeholder="อื่น ๆ"
                  height="32px"
                  hide-details/>
              <v-text-field
                  v-if="sellerId"
                  v-model="otherAdd.cost"
                  outlined
                  placeholder="ต้นทุน"
                  suffix="บาท"
                  height="32px"
                  class="mt-1"
                  type="number"
                  hide-details/>
            </td>
            <td class="text-center">
              <v-text-field
                  class="centered-input"
                  v-model="otherAdd.price_per_unit"
                  outlined
                  placeholder="จำนวน"
                  height="32px"
                  type="number"
                  hide-details/>
            </td>
            <td>
              <v-text-field
                  class="centered-input"
                  v-model="otherAdd.quantity"
                  outlined
                  placeholder="จำนวน"
                  height="32px"
                  type="number"
                  hide-details/>
            </td>
            <td class="text-center">
              {{ otherAdd.price_per_unit * otherAdd.quantity || '' }}
            </td>
            <td>
              <v-text-field
                  class="centered-input"
                  v-model="otherAdd.discount"
                  outlined
                  placeholder="ส่วนลด"
                  height="32px"
                  type="number"
                  hide-details/>
            </td>
            <td class="text-center">
              {{ (otherAdd.price_per_unit * otherAdd.quantity) - otherAdd.discount || '' }}
            </td>
            <td v-if="sellerId">
              <v-text-field outlined
                            v-model="otherAdd.commission_rate"
                            suffix="%"
                            class="centered-input"
                            height="32px"
                            hide-details
                            type="number"/>
            </td>
            <td>
              <v-icon
                  color="success"
                  class="mr-2"
                  @click="beforeAddOther()"
                  :disabled="!(otherAdd.title && otherAdd.price_per_unit > 0 && otherAdd.quantity > 0 && otherAdd.discount >= 0)"
              >
                mdi-plus-circle
              </v-icon>
              <v-icon
                  color="error"
                  class="mr-2"
                  @click="typeAdd = 0"
              >
                mdi-close-circle
              </v-icon>
            </td>
          </tr>
        </template>

<!--        <template v-if="deliveryType === 'ออกไปส่ง' || deliveryType === 'จ้างรถข้างนอก'" slot="body.append">-->
<!--          <tr style="background-color: #ffffff">-->
<!--            <td>-->
<!--              ค่าส่ง-->
<!--            </td>-->
<!--            <td class="text-center">-->
<!--               <span v-if="deliveryType === 'ออกไปส่ง'">{{-->
<!--                   orderItems.delivery.price-->
<!--                 }}</span>-->
<!--            </td>-->
<!--            <td class="text-center">-->
<!--              <span v-if="deliveryType === 'ออกไปส่ง'">{{ countOrder }}</span>-->
<!--            </td>-->
<!--            <td class="text-center">-->
<!--              <span-->
<!--                  v-if="deliveryType === 'ออกไปส่ง'">{{-->
<!--                  orderItems.delivery.price * orderItems.delivery.amount-->
<!--                }}</span>-->
<!--              <v-text-field v-else-->
<!--                            :disabled="!(updateOrderProps.order_status ==='ใบเสนอราคา' || updateOrderProps.order_status ==='รับออเดอร์') || updateOrderProps.payment_status === 'ชำระแล้ว'"-->
<!--                            class="centered-input"-->
<!--                            v-model="orderItems.delivery.total_price"-->
<!--                            outlined-->
<!--                            placeholder="จำนวน"-->
<!--                            height="32px"-->
<!--                            type="number"-->
<!--                            hide-details/>-->
<!--            </td>-->
<!--            <td>-->
<!--              <v-text-field-->
<!--                  :disabled="!(updateOrderProps.order_status ==='ใบเสนอราคา' || updateOrderProps.order_status ==='รับออเดอร์') || updateOrderProps.payment_status === 'ชำระแล้ว'"-->
<!--                  class="centered-input"-->
<!--                  v-model="orderItems.delivery.discount"-->
<!--                  outlined-->
<!--                  :rules="[rules.minZero]"-->
<!--                  placeholder="จำนวน"-->
<!--                  height="32px"-->
<!--                  type="number"-->
<!--                  hide-details/>-->
<!--            </td>-->
<!--            <td class="text-center">-->
<!--              <span-->
<!--                  v-if="deliveryType==='จ้างรถข้างนอก'">{{-->
<!--                  orderItems.delivery.total_price - orderItems.delivery.discount-->
<!--                }}</span>-->
<!--              <span-->
<!--                  v-else>{{-->
<!--                  (orderItems.delivery.price * orderItems.delivery.amount) - orderItems.delivery.discount-->
<!--                }}</span>-->
<!--            </td>-->
<!--            <td v-if="sellerId">-->
<!--              <v-text-field outlined-->
<!--                            :rules="[rules.minZero]"-->
<!--                            v-model="orderItems.delivery.commission_rate"-->
<!--                            suffix="%"-->
<!--                            class="centered-input"-->
<!--                            height="32px"-->
<!--                            hide-details-->
<!--                            type="number"/>-->
<!--            </td>-->
<!--            <td>-->
<!--              <v-btn-->
<!--                  :disabled="!updateOrderProps.isActive"-->
<!--                  v-if="(updateOrderProps.order_status ==='ใบเสนอราคา' || updateOrderProps.order_status ==='รับออเดอร์' || updateOrderProps.order_status ==='ดำเนินการ') && !forCreate"-->
<!--                  @click="editDelivery" icon color="green">-->
<!--                <v-icon>mdi-content-save</v-icon>-->
<!--              </v-btn>-->
<!--            </td>-->
<!--          </tr>-->
<!--        </template>-->

        <template slot="body.append">
          <tr style="background-color: #eaf8ff">
            <td>
              รวม.
            </td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td class="text-center">{{ (sumTotal).toFixed(2) }}</td>
            <td>บาท</td>
            <td v-if="sellerId"></td>
          </tr>
          <tr style="background-color: #fff9ea">
            <td>
              ส่วนลด
            </td>
            <td></td>
            <td></td>
            <td></td>
            <td class="text-center">
              <v-text-field
                  :disabled="!(updateOrderProps.order_status ==='ใบเสนอราคา' || updateOrderProps.order_status ==='รับออเดอร์') || updateOrderProps.payment_status === 'ชำระแล้ว'"
                  type="number" height="32" outlined hide-details :value="discount" class="centered-input"
                  @input="$emit('update:discount',parseFloat($event) || 0)"></v-text-field>
            </td>
            <td class="text-center">{{ (sumTotal - discount).toFixed(2) }}</td>
            <td>บาท</td>
            <td v-if="sellerId"></td>
          </tr>
        </template>

        <template slot="body.append">
          <tr style="background-color: #eeeeee">
            <td>
              <v-checkbox
                  :disabled="!(updateOrderProps.order_status ==='ใบเสนอราคา' || updateOrderProps.order_status ==='รับออเดอร์') || updateOrderProps.payment_status === 'ชำระแล้ว'"
                  hide-details
                  class="mt-0"
                  v-model="orderItems.hasVat"
                  label="vat 7 %"
              ></v-checkbox>
            </td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td class="text-center">{{ ((sumTotal - discount) * 0.07).toFixed(2) }}</td>
            <td>บาท</td>
            <td v-if="sellerId"></td>
          </tr>
        </template>

        <template slot="body.append">
          <tr style="background-color: #eaffec">
            <td>
              ราคาสุทธิ
            </td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td class="text-center">{{
                ((sumTotal - discount) * (orderItems.hasVat ? 1.07 : 1)).toFixed(2)
              }}
            </td>
            <td>บาท</td>
            <td v-if="sellerId"></td>
          </tr>
        </template>

      </v-data-table>
      <v-row
          v-if="(updateOrderProps.order_status ==='ใบเสนอราคา' || updateOrderProps.order_status ==='รับออเดอร์') && (forCreate ? true : updateOrderProps.isActive)"
          class="mt-2 px-4 mx-0">
        <v-btn @click="typeAdd = 1"
               :disabled="(typeAdd !== 0 && typeAdd !== 1) || updateOrderProps.payment_status === 'ชำระแล้ว'" depressed
               class="mr-2" small
               style="background-color: #6464cc;color: white">+ เพิ่มสินค้า
        </v-btn>
        <v-btn @click="typeAdd = 2"
               :disabled="(typeAdd !== 0 && typeAdd !== 2) || updateOrderProps.payment_status === 'ชำระแล้ว'" depressed
               class="mr-2" small
               style="background-color: orange;color: white">+
          ของแถม
        </v-btn>
        <!--        <v-btn @click="typeAdd = 3" :disabled="typeAdd !== 0 && typeAdd !== 3" depressed class="mr-2" small-->
        <!--               style="background-color:#5bc0de;color: white">+ ส่วนลด-->
        <!--        </v-btn>-->
        <v-btn @click="typeAdd = 4"
               :disabled="(typeAdd !== 0 && typeAdd !== 4) || updateOrderProps.payment_status === 'ชำระแล้ว'" depressed
               class="mr-2" small
               style="background-color:#4f4f4f;color: white">+ อื่น ๆ
        </v-btn>
      </v-row>
    </v-form>

    <!--    remove dialog-->
    <v-dialog
        v-model="showRemoveDialog"
        persistent
        max-width="420"
    >
      <v-card>
        <div class="ml-6 pt-4" style="font-size: 1.45rem">ต้องการนำรายการนี้ออกหรือไม่</div>
        <v-card-text class="mt-4 text-body-1">
          {{ willRemoveItem.item_name }}
          {{ willRemoveItem.item_quantity ? `x${willRemoveItem.item_quantity}` : '' }}
          {{ willRemoveItem.item_total ? `ราคา ${willRemoveItem.item_total} บาท` : '' }}
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
              text
              @click="showRemoveDialog = false"
          >
            ยกเลิก
          </v-btn>
          <v-btn
              color="error"
              dark
              @click=" removeOrderItem(willRemoveItem);showRemoveDialog = false"
          >
            <v-icon left>mdi-delete</v-icon>
            นำออก
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import axios from 'axios'

export default {
  props: {
    sellerId: {
      type: Number,
      default: null
    },
    value: Object,
    forCreate: Boolean,
    deliveryType: String,
    countOrder: Number,
    updateOrderProps: Object,
    orderStatus: String,
    discount: {
      type: Number,
      default: null
    }
  },
  name: "TableSelectProduct",
  data() {
    return {
      rules: {
        required: value => !!value || "ต้องกรอก",
        required_select: value => !!value || "ต้องเลือก",
        min: value => value > 0 || "จำนวนต้องเป็นตั้งแต่ 1 ขึ้นไป",
        minZero: value => value >= 0 || 'จำนวนต้องเป็นตั้งแต่ 0 ขึ้นไป'
      },
      header: [
        {
          text: 'รายการ',
          value: 'item_name',
          align: 'left',
        },
        {
          text: 'ราคา/หน่วย',
          value: 'item_price_per_unit',
          width: 130,
          align: 'center'
        },
        {
          text: 'จำนวน',
          value: 'item_quantity',
          width: 100,
          align: 'center'
        },
        {
          text: 'ราคา',
          value: 'item_total',
          width: 130,
          align: 'center'
        },
        {
          text: 'ส่วนลด',
          value: 'discount',
          width: 150,
          align: 'center'
        },
        {
          text: 'รวม',
          value: 'total',
          width: 80,
          align: 'center'
        },
        {
          text: '',
          value: 'actions',
          sortable: false,
          width: 300
        },
      ],
      productSelect: [],
      giftProductSelect: [],
      typeAdd: 0,
      productAdd: {
        product: {},
        quantity: 1,
        discount: 0,
        price_per_unit: 0
      },
      giftAdd: {
        product: {},
        quantity: 1,
      },
      discountAdd: {
        title: 'ส่วนลด',
        discount: 0
      },
      otherAdd: {
        title: '',
        price_per_unit: 0,
        quantity: 1,
        discount: 0,
        cost: 0,
        commission_rate: 0
      },
      valid: true,
      showRemoveDialog: false,
      willRemoveItem: {},
      deliveryDiscount: 0
      // deliveryPrice: 0
    }
  },
  created() {
    this.getProduct()
  },
  methods: {
    // showCommission(item) {
    //   if (item) {
    //     return item
    //   }
    // },
    price(item) {
      if (this.deliveryType === 'ลูกค้ามารับ') {
        return item.price
      } else {
        return item.price_for_ship
      }
    },
    getProduct() {
      axios.get('/api/stocks/product/')
          .then(res => {
            console.log(res.data.data)
            this.productSelect = res.data.data.filter(p => p.category.name !== 'ของแถม')
            this.giftProductSelect = res.data.data.filter(p => p.category.name === 'ของแถม')
          })
          .catch(e => {
            console.log(e)
          })
    },
    async addProduct() {
      // console.log(this.productAdd.product)
      if (this.forCreate === false) {
        let payload = {
          order_id: this.$route.params.id,
          product_id: this.productAdd.product.id,
          price_per_unit: this.productAdd.price_per_unit,
          quantity: this.productAdd.quantity,
          discount: this.productAdd.discount
        }
        await axios.post('/api/orders/order_item/', payload)
            .then(res => {
              console.log(res)
              window.location.reload()
            }).catch(e => {
              console.log(e)
            })
      }

      console.log(this.productAdd)

      this.orderItems.item.push({
        product_id: this.productAdd.product.id,
        item_name: this.productAdd.product.name,
        item_price_per_unit: this.productAdd.price_per_unit,
        item_quantity: this.productAdd.quantity,
        item_total: (this.productAdd.price_per_unit * this.productAdd.quantity).toFixed(2),
        discount: this.productAdd.discount,
        total: ((this.productAdd.price_per_unit * this.productAdd.quantity) - this.productAdd.discount).toFixed(2),
        commission_rate: this.productAdd.product.commission_rate,
        category: "product"
      })

      this.typeAdd = 0

      this.productAdd = {
        product: {},
        quantity: 1,
        discount: 0
      }

      console.log(this.orderItems)

      this.$emit('input', this.orderItems)
    },
    async addGiftProduct() {

      if (this.forCreate === false) {
        let payload = {
          order_id: this.$route.params.id,
          product_id: this.giftAdd.product.id,
          quantity: this.giftAdd.quantity,
          price_per_unit: this.productAdd.price_per_unit,
          discount: 0
        }
        await axios.post('/api/orders/order_item/', payload)
            .then(res => {
              console.log(res)
              window.location.reload()
            }).catch(e => {
              console.log(e)
            })
      }

      this.orderItems.item.push({
        product_id: this.giftAdd.product.id,
        item_name: this.giftAdd.product.name,
        // item_price_per_unit: 0,
        item_quantity: this.giftAdd.quantity,
        // item_total: 0,
        discount: 0,
        total: 0,
        category: "gift",
        item_price_per_unit: 0,
      })

      this.typeAdd = 0

      this.giftAdd = {
        product: {},
        quantity: 0,
      }

      this.$emit('input', this.orderItems)
    },
    async addDiscount() {
      if (this.forCreate === false) {
        console.log(this.updateOrderProps)
        let payload = {
          id: this.updateOrderProps.id,
          // payment_method: this.updateOrderProps.payment_method,
          // payment_status: this.updateOrderProps.payment_status,
          // order_status: this.updateOrderProps.order_status,
          // delivery_date: this.updateOrderProps.delivery_date,
          // hasVat: this.updateOrderProps.hasVat,
          discount: this.updateOrderProps.discount + parseFloat(this.discountAdd.discount)
        }
        await axios.put('/api/orders/', payload)
            .then(res => {
              console.log(res.data)
              window.location.reload()
            }).catch(e => {
              console.log(e)
            })
      }

      this.orderItems.item.push({
        product_id: null,
        item_name: 'ส่วนลด',
        // item_price_per_unit: 0,
        // item_quantity: this.giftAdd.quantity,
        item_total: this.discountAdd.discount,
        // discount: 0,
        total: this.discountAdd.discount * (-1),
        category: "discount"
      })

      this.typeAdd = 0

      this.discountAdd = {
        title: 'ส่วนลด',
        discount: 0
      }

      this.$emit('input', this.orderItems)
    },
    async beforeAddOther() {
      if (this.sellerId && (this.otherAdd.commission_rate <= 0 || this.otherAdd.commission_rate === undefined)) {
        if (confirm('มีการใส่เลือก sale ไว้ แต่ค่าคอมมิชชั่นเป็น 0% ต้องการดำเนินการต่อหรือไม่')) {
          await this.addOther()
        }
      } else {
        await this.addOther()
      }
    },
    async addOther() {
      let total_price = (this.otherAdd.price_per_unit * this.otherAdd.quantity)
      if (this.forCreate === false) {
        console.log('payload')
        let payload = {
          order_id: this.$route.params.id,
          product_id: null,
          name: this.otherAdd.title,
          unit: "รายการ",
          quantity: this.otherAdd.quantity,
          discount: this.otherAdd.discount,
          total_price: total_price - this.otherAdd.discount,
          commission_rate: this.otherAdd.commission_rate || 0,
          cost: parseFloat(this.otherAdd.cost || '0'),
          commission_price: (((total_price - parseFloat(this.otherAdd.cost || '0')) - parseFloat(this.otherAdd.discount)) * (parseFloat(this.otherAdd.commission_rate) / 100)).toFixed(2)
        }
        console.log(payload)
        await axios.post('/api/orders/order_item/', payload)
            .then(res => {
              console.log(res)
              window.location.reload()
            }).catch(e => {
              console.log(e)
            })
      }

      this.orderItems.item.push({
        product_id: null,
        item_name: this.otherAdd.title,
        item_price_per_unit: this.otherAdd.price_per_unit,
        item_quantity: this.otherAdd.quantity,
        item_total: (this.otherAdd.price_per_unit * this.otherAdd.quantity).toFixed(2),
        discount: this.otherAdd.discount,
        total: ((this.otherAdd.price_per_unit * this.otherAdd.quantity) - this.otherAdd.discount).toFixed(2),
        category: "other",
        commission_rate: this.otherAdd.commission_rate || 0,
        cost: this.otherAdd.cost || 0,
        commission_price: (((total_price - parseFloat(this.otherAdd.cost || '0')) - parseFloat(this.otherAdd.discount)) * (parseFloat(this.otherAdd.commission_rate) / 100)).toFixed(2)
      })

      this.typeAdd = 0

      this.otherAdd = {
        title: '',
        price_per_unit: 0,
        quantity: 1,
        discount: 0
      }

      this.$emit('input', this.orderItems)
    },
    async removeOrderItem(value) {
      console.log(value)
      if (this.forCreate === false) {
        axios.delete(`/api/orders/order_item/${value.id}/`)
            .then((res) => {
              console.log(res.data.data)
              this.$store.state.snackbar = {
                text: `บันทึกการแก้ไขในออเดอร์นี้แล้ว`,
                visible: true,
                color: 'success'
              }
            }).catch(e => {
          console.log(e)
        })
      }
      this.orderItems.item.splice(this.orderItems.item.indexOf(value), 1)
      this.$emit('input', this.orderItems)
    },
    editOrderItem(item) {
      if (item.item_quantity <= 0 || item.discount < 0 || item.commission_rate < 0) {
        this.$store.state.snackbar = {
          text: `กรอกไม่ถูกต้อง`,
          visible: true,
          color: 'error'
        }
      } else {
        let payload = {
          cost: item.cost,
          id: item.id,
          quantity: item.item_quantity,
          discount: item.discount,
          // total_price: item.item_total - item.discount,
          commission_rate: item.commission_rate,
          price_per_unit: parseFloat(item.item_price_per_unit),
          // commission_price: item.item_total
        }

        axios.put('/api/orders/order_item/', payload)
            .then(res => {
              console.log(res)
              this.$store.state.snackbar = {
                text: `บันทึกการแก้ไขของรายการออเดอร์นี้แล้ว`,
                visible: true,
                color: 'success'
              }
            }).catch(e => {
          console.error(e)
        })
      }

    },
    editDelivery(item) {
      if (this.orderItems.delivery.discount < 0 || this.orderItems.delivery.commission_rate < 0) {
        this.$store.state.snackbar = {
          text: `กรอกไม่ถูกต้อง`,
          visible: true,
          color: 'error'
        }
      } else {
        let payload = {
          id: this.orderItems.delivery.id,
          quantity: this.orderItems.delivery.amount,
          discount: this.orderItems.delivery.discount,
          // total_price: item.item_total - item.discount,
          commission_rate: this.orderItems.delivery.commission_rate,
          // commission_price: item.item_total
        }
        console.log(payload, item)

        axios.put('/api/orders/order_item/', payload)
            .then(res => {
              console.log(res)
              this.$store.state.snackbar = {
                text: `บันทึกการแก้ไขค่าส่งของออเดอร์นี้แล้ว`,
                visible: true,
                color: 'success'
              }
            }).catch(e => {
          console.error(e)
        })
      }
    }
  },
  computed: {
    sumTotal() {
      let sum = this.orderItems.item.reduce((a, b) => a + (((b.item_price_per_unit * b.item_quantity) - b.discount) || 0), 0)
      let total_price_trip = this.deliveryType === 'จ้างรถข้างนอก' ? this.orderItems.delivery.total_price : this.orderItems.delivery.price * this.orderItems.delivery.amount
      let shipping_price = total_price_trip - this.orderItems.delivery.discount
      return sum + shipping_price
    },
    orderItems() {
      return this.value
    },
    headersCompute() {
      if (this.sellerId) {
        return [
          {
            text: 'รายการ',
            value: 'item_name',
            align: 'left',
          },
          {
            text: 'ราคา/หน่วย',
            value: 'item_price_per_unit',
            width: 130,
            align: 'center'
          },
          {
            text: 'จำนวน',
            value: 'item_quantity',
            width: 100,
            align: 'center'
          },
          {
            text: 'ราคา',
            value: 'item_total',
            width: 130,
            align: 'center'
          },
          {
            text: 'ส่วนลด',
            value: 'discount',
            width: 150,
            align: 'center'
          },
          {
            text: 'รวม',
            value: 'total',
            width: 80,
            align: 'center'
          },
          {
            text: '%เซลล์',
            value: 'commission_rate',
            width: 120,
            align: 'center'
          },
          {
            text: '',
            value: 'actions',
            sortable: false,
            width: 120,
            class: 'px-0'
          },
        ]
      } else {
        return [
          {
            text: 'รายการ',
            value: 'item_name',
            align: 'left',
          },
          {
            text: 'ราคา/หน่วย',
            value: 'item_price_per_unit',
            width: 130,
            align: 'center'
          },
          {
            text: 'จำนวน',
            value: 'item_quantity',
            width: 100,
            align: 'center'
          },
          {
            text: 'ราคา',
            value: 'item_total',
            width: 130,
            align: 'center'
          },
          {
            text: 'ส่วนลด',
            value: 'discount',
            width: 150,
            align: 'center'
          },
          {
            text: 'รวม',
            value: 'total',
            width: 80,
            align: 'center'
          },
          {
            text: '',
            value: 'actions',
            sortable: false,
            width: 120,
            class: 'px-0'
          },
        ]
      }
    }
  },
  watch: {
    // sellerId(value) {
    //   if (value) {
    //     this.header.splice(6, 0, {
    //       text: '%เซลล์',
    //       value: 'commission_rate',
    //       width: 120,
    //       align: 'center'
    //     },)
    //   } else if (value === null) {
    //     let index = this.header.findIndex((e) => e.value === 'commission_rate')
    //     if (index > 0) {
    //       this.header.splice(index, 1)
    //     }
    //   }
    // },
    deliveryType(value) {
      if (value === 'ออกไปส่ง') {
        this.orderItems.delivery.total_price = this.orderItems.delivery.amount * this.orderItems.delivery.price
      } else if (value === 'จ้างรถข้างนอก' || value === 'ลูกค้ามารับ') {
        this.orderItems.delivery.total_price = 0
        this.orderItems.delivery.price = 0
      }
      this.orderItems.delivery.discount = 0
    },
  }
}
</script>

<style scoped>
.v-data-table-header {
  border-bottom: none !important;
  background-color: #c7ddef !important
}

.centered-input >>> input {
  text-align: center
}
</style>
