<template>
  <v-dialog persistent max-width="787" v-model="value">
    <v-card v-if="trip">
      <v-card-title>
        เที่ยวที่ {{ trip.no }} : ระบุของที่ส่ง
        <v-chip class="ml-4" color="success" v-if="trip.is_delivered">ส่งแล้ว</v-chip>
      
      </v-card-title>
      <v-card-text>
        <v-simple-table>
          <template v-slot:default>
            <tbody>
            <tr>
              <td>ชื่อคนขับ</td>
              <td>{{ trip.driver.first_name }} {{ trip.driver.last_name }}</td>
            </tr>
            <tr>
              <td>รถ</td>
              <td>{{ trip.vehicle.name }} / <span class="font-weight-bold indigo--text"> ทะเบียน {{
                  trip.vehicle.licence
                }}</span></td>
            </tr>
            </tbody>
          </template>
        </v-simple-table>
        <v-divider class="mb-2"/>
        
        <h3 class="mt-10">ถ้าไม่ได้บรรทุกสินค้าบางรายการ ไม่ต้องกรอก</h3>
        
        
        <v-data-table hide-default-footer :headers="headers"
                      :items="trip_order_item">
          <template v-slot:item.amount="{item}">
            <v-text-field :disabled="trip.is_delivered" v-model="item.amount"
                          :rules="[rules.numberRule(item.item_quantity)]" outlined dense
                          hide-details="auto" type="number"></v-text-field>
          </template>
        </v-data-table>
      </v-card-text>
      <v-card-actions>
        <v-btn text @click="close()"> ปิด</v-btn>
        <v-spacer/>
        <v-btn v-if="!trip.is_delivered" @click="submitAndPrint()" color="success" elevation="0">
          <v-icon left>mdi-content-save</v-icon>
          บันทึกและพิมพ์
        </v-btn>
        <v-btn v-else @click="print()" color="success" elevation="0">
          <v-icon left>mdi-printer</v-icon>
          พิมพ์
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import axios from 'axios'

export default {
  name: "TripDeliveryDialog",
  props: {
    value: {
      type: Boolean,
      default: false
    },
    items: {
      type: Array,
      default: () => ([])
    },
    orderItems: {
      type: Array,
      default: () => ([])
    },
    trip: {
      type: Object,
      default: () => ({})
    }
  },
  // watch: {
  //   trip(val) {
  //     this.selected = val.order_item.map(i => ({
  //       amount: i.amount,
  //       ...this.orderItems.find(item => i.order_item_id === item.id)
  //     }))
  //   }
  // },
  data() {
    return {
      rules: {
        required: value => value.length > 0 || "ต้องกรอก",
        required_select: value => !!value || "เลือกหมวดสินค้า",
        min: value => value >= 0 || "จำนวนต้องเป็นตั้งแต่ 0 ขึ้นไป",
        number_lt: (n) => (value) =>
          Number(value) <= n || `จำนวนต้องน้อยกว่าหรือเท่ากับ ${n}`,
        empty_or_numeric_gte: (n) => (value) => {
          if (value !== undefined && isNaN(value)) {
            console.log(value)
            if (Number(value) >= n) {
              return true
            } else {
              `จำนวนต้องน้อยกว่าหรือเท่ากับ ${n}`
            }
          } else {
            return true;
          }
        },
        numberRule: (n) => v => {
          if (!v.trim()) return true;
          if (!isNaN(parseFloat(v)) && v >= 0 && v <= n) return true;
          return `จำนวนต้องน้อยกว่าหรือเท่ากับ ${n}`;
        },
      },
      headers: [
        {
          text: 'สินค้า',
          value: 'item_name',
          sortable: false
        },
        {
          text: 'จำนวนทั้งหมด',
          value: 'item_quantity',
          sortable: false
        },
        {
          text: 'จำนวนที่บรรทุก',
          value: 'amount',
          width: '200',
          sortable: false
        },
        {
          text: 'หน่วย',
          value: 'unit',
          sortable: false,
          align: 'center'
        }
      ],
      selected: [],
      items_selected: []
    }
  },
  computed: {
    trip_order_item() {
      if (this.trip && this.trip.order_item) {
        if (this.trip.order_item.length) {
          return this.trip.order_item.map(o => ({ ...o, item_name: o.order_item_name, unit: o.order_item_unit }))
        } else {
          return this.orderItems
        }
      }
      return []
      
    }
  },
  methods: {
    close() {
      // this.items_selected = []
      this.$emit('clear-trip')
      this.$emit('input', false)
      this.$emit('close', false)
    },
    // addItem(item) {
    //   this.items_selected.push({
    //     order_item_id: item.id,
    //     item_name: item.item_name,
    //     unit: item.unit,
    //     amount: parseFloat(item.amount)
    //   })
    // },
    submitAndPrint() {
      let form = {
        items: this.orderItems.filter(s => s.amount).map(s => ({
          trip_order_item_id: s.trip_order_item_id || null,
          order_item_id: s.id,
          item_name: s.item_name,
          unit: s.unit,
          amount: parseFloat(s.amount)
        })),
        trip_id: this.trip.id
      }
      console.log(form)
      axios.post('/api/stocks/trip/print/', form)
        .then(res => {
          this.print()
          window.location.reload();
          // this.$emit('submit', false)
        })
        .catch(e => {
          console.error(e)
        })
      
    },
    print() {
      window.open(`${this.$store.state.endpoints.baseUrL}/api/orders/delivery2/${this.$route.params.id}/?trip_id=${this.trip.id}`, '_blank')
    }
  }
}
</script>

<style scoped>

</style>