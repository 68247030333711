<template>
  <div class="main-container">
    <v-toolbar
      flat
      tile
      class="box box-1"
      style="background-color: transparent"
    >
      <!--      <v-btn icon @click="window.close();">-->
      <!--        <v-icon>mdi-arrow-left</v-icon>-->
      <!--      </v-btn>-->
      <v-toolbar-title class="pl-0"> Order </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn
        v-if="
          !isMobile() &&
          $store.state.authUser.user_permissions.includes('change_order')
        "
        @click="saveEditOrder()"
        depressed
        color="success"
        class="mr-2"
      >
        <v-icon left>mdi-content-save</v-icon>
        บันทึกการแก้ไขข้อมูลในออเดอร์
      </v-btn>
      <v-btn
        v-if="
          !isMobile() &&
          $store.state.authUser.user_permissions.includes('delete_order') &&
          orderDetail.order.isActive
        "
        @click="showConfirmCancel = true"
        depressed
        color="error"
      >
        ยกเลิกออเดอร์นี้
      </v-btn>

      <v-btn
        v-if="
          !isMobile() &&
          ($store.state.authUser.username === 'admin' ||
            $store.state.authUser.username === 'sammy') &&
          !orderDetail.order.isActive
        "
        @click="showConfirmRemoveOrder = true"
        depressed
        color="error"
      >
        ลบออเดอร์นี้ออกจากระบบ
      </v-btn>

      <!--      <v-btn-->
      <!--          v-if="!isMobile() && $store.state.authUser.user_permissions.includes('delete_order') && !orderDetail.order.isActive"-->
      <!--          @click="showConfirmCancel = true" depressed color="error">-->
      <!--        <v-icon>mdi-exit-to-app</v-icon>-->
      <!--        นำออเดอร์ออกจากระบบ-->
      <!--      </v-btn>-->
    </v-toolbar>
    <v-card v-if="!isMobile()" class="mx-4 box box-2" style="min-height: 100%">
      <splitpanes>
        <pane
          min-size="20"
          :size="40"
          class="pr-2 pl-4 py-4"
          style="overflow: auto"
        >
          <v-row v-if="orderStatus === 'ใบเสนอราคา'" justify="space-between">
            <v-col align-self="center">หมายเลขใบเสนอราคา</v-col>
            <v-col class="text-right">
              {{ orderDetail.order.id }}
            </v-col>
          </v-row>
          <v-row v-else justify="space-between">
            <v-col align-self="center">หมายเลข Order</v-col>
            <v-col>
              <!--              {{ orderDetail }}-->
              <v-row>
                <v-col align-self="center" class="text-right pr-1"
                  >{{ year }}/</v-col
                >
                <v-col class="pl-0">
                  <v-text-field
                    v-model="id_display"
                    outlined
                    placeholder="ใส่เลขสั่งซื้อ"
                    type="number"
                    height="32px"
                    hide-details="auto"
                  />
                </v-col>
              </v-row>
            </v-col>
          </v-row>

          <v-row justify="space-between">
            <v-col align-self="center">หมายเลข PO อ้างอิง</v-col>
            <v-col class="text-right">
              <v-text-field
                v-model="client_refer"
                outlined
                placeholder="กรอกข้อมูล"
                height="32px"
                hide-details="auto"
              />
            </v-col>
          </v-row>

          <v-row justify="space-between">
            <v-col align-self="center">เปิดวันที่</v-col>
            <v-col class="text-right">
              <v-text-field disabled
                v-model="orderDetail.order.order_date"
                outlined
                placeholder="กรอกข้อมูล"
                height="32px"
                hide-details="auto"
              />
            </v-col>
          </v-row>

          <v-row justify="space-between">
            <v-col align-self="center">เปิดโดย</v-col>
            <v-col class="text-right">
              <v-text-field disabled
                :value="orderDetail.order.responsible_user.first_name + ' ' + orderDetail.order.responsible_user.last_name"
                outlined
                placeholder="กรอกข้อมูล"
                height="32px"
                hide-details="auto"
              />
            </v-col>
          </v-row>

          <v-row justify="space-between">
            <v-col align-self="center">ชื่อลูกค้า</v-col>
            <v-col class="text-right">
              <v-text-field
                disabled
                v-model="customer_name"
                outlined
                placeholder="กรอกข้อมูล"
                height="32px"
                hide-details
              />
            </v-col>
          </v-row>

          <v-row justify="space-between">
            <v-col align-self="center">เบอร์โทร</v-col>
            <v-col class="text-right">
              <v-text-field
                disabled
                v-model="customer_tel"
                type="number"
                outlined
                placeholder="กรอกข้อมูล"
                height="32px"
                hide-details
              />
            </v-col>
          </v-row>

          <v-row justify="space-between" class="mt-0">
            <v-col align-self="center">เลือกประเภทการจัดส่ง</v-col>
            <v-col class="text-right">
              <v-select
                disabled
                v-model="deliveryType"
                class="rounded mt-3"
                :items="deliveryTypeSelect"
                outlined
                dense
                height="32px"
                hide-details
                placeholder="เลือกประเภทการจัดส่ง"
              ></v-select>
            </v-col>
          </v-row>

          <div v-if="deliveryType !== 'ลูกค้ามารับ'">
            <v-row justify="space-between" class="mt-2">
              <v-col>ที่อยู่จัดส่ง</v-col>
              <v-col class="text-right">
                <v-textarea
                  disabled
                  placeholder="รายละเอียดที่อยู่จัดส่ง"
                  hide-details
                  rows="2"
                  outlined
                  v-model="customer_address"
                ></v-textarea>
              </v-col>
            </v-row>

            <v-expansion-panels v-if="latLng.lat !== null" flat>
              <v-expansion-panel>
                <v-expansion-panel-header class="px-0">
                  จุดหมายบนแผนที่
                  <template v-slot:actions>
                    <v-icon color="primary"> $expand </v-icon>
                  </template>
                </v-expansion-panel-header>
                <v-expansion-panel-content class="pb-0">
                  <Map
                    @getDistance="getDistance"
                    :lat-lng="latLng"
                    :view-mode="true"
                  />
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>

            <v-row justify="space-between" class="mt-4">
              <v-col align-self="center">ระยะทาง</v-col>
              <v-col class="text-right">
                <v-text-field
                  v-model="distance"
                  suffix="กม."
                  outlined
                  placeholder="กรอกข้อมูล"
                  height="32px"
                  type="number"
                  disabled
                  hide-details
                />
                <!--                {{distance}} กม.-->
              </v-col>
            </v-row>

            <!--            <v-row v-if="latLng.lat" justify="space-between" class="mt-2">-->
            <!--              <v-col>Google Map Link</v-col>-->
            <!--              <v-col class="text-right">-->
            <!--                <span><a-->
            <!--                    :href="latLng.lat" target="_blank">เปิดแผนที่</a></span>-->
            <!--              </v-col>-->
            <!--            </v-row>-->
          </div>

          <v-row justify="space-between" class="mt-4">
            <v-col align-self="center">เลือกคนตัก</v-col>
            <v-col class="text-right">
              <v-select
                :disabled="
                  !(
                    orderStatus === 'ใบเสนอราคา' || orderStatus === 'รับออเดอร์'
                  ) || !orderDetail.order.isActive
                "
                outlined
                dense
                height="32px"
                class="rounded"
                v-model="scooper_id"
                :items="scoopSelect"
                item-text="first_name"
                item-value="id"
                :rules="[rules.required_select]"
                hide-details="auto"
                placeholder="เลือกคนขับรถ"
                return-object
              >
                <template slot="selection" slot-scope="data">
                  {{ data.item.first_name }} {{ data.item.last_name }}
                </template>
                <template slot="item" slot-scope="data">
                  {{ data.item.first_name }} {{ data.item.last_name }}
                </template>
              </v-select>
            </v-col>
          </v-row>

          <p style="color: gray; font-size: 0.85rem; padding-top: 4px">
            **ถ้ารายการสินค้าที่อยู่ในออเดอร์ไม่มีการคิดค่าตัก
            ระบบจะไม่คิดค่าตักของคนตักที่เลือก**
          </p>

          <v-row justify="space-between" class="mt-4">
            <v-col align-self="center">เลือกเซลล์</v-col>
            <v-col class="text-right">
              <v-select
                :disabled="
                  !(
                    orderStatus === 'ใบเสนอราคา' ||
                    orderStatus === 'รับออเดอร์' ||
                    orderStatus === 'ดำเนินการ'
                  )
                "
                clearable
                outlined
                dense
                height="32px"
                class="rounded"
                v-model="seller_id"
                :items="sellerOptions"
                item-value="id"
                hide-details="auto"
                placeholder="ไม่ได้เลือกเซลล์"
              >
                <template slot="selection" slot-scope="data">
                  {{ data.item.first_name }} {{ data.item.last_name }}
                </template>
                <template slot="item" slot-scope="data">
                  {{ data.item.first_name }} {{ data.item.last_name }}
                </template>
              </v-select>
            </v-col>
          </v-row>

          <div v-if="deliveryType === 'ออกไปส่ง'">
            <v-row class="mt-4">
              <v-col align-self="center">
                <span class="mr-2 font-weight-bold"
                  >เที่ยวรถ ({{ trips.length }})</span
                >
                <!--                <v-icon @click="addTrip" color="success">mdi-plus-circle</v-icon>-->
              </v-col>
            </v-row>

            <v-data-table
              :items-per-page="-1"
              :items="trips"
              :headers="tripHeader"
              :header-props="{ sortIcon: null }"
              sort-by="id"
              hide-default-footer
            >
              <template #item="{ item }">
                <tr :class="{ 'green lighten-5': item.is_delivered }">
                  <td class="pl-2 pr-0">
                    <v-btn
                      v-show="
                        !(
                          orderStatus === 'ใบเสนอราคา' ||
                          orderStatus === 'รับออเดอร์'
                        )
                      "
                      @click="
                        showTripDeliveryDialog = true;
                        current_trip = { ...item, no: trips.indexOf(item) + 1 };
                      "
                      icon
                    >
                      <v-icon>mdi-printer</v-icon>
                    </v-btn>
                    {{ trips.indexOf(item) + 1 }}
                  </td>
                  <td class="pl-0">
                    <v-select
                      :disabled="!(item.driver_id === willEditTrip.driver_id)"
                      outlined
                      dense
                      height="32px"
                      class="rounded"
                      v-model="item.driver_id"
                      :items="driverSelect"
                      item-text="first_name"
                      item-value="id"
                      :rules="[rules.required_select]"
                      hide-details="auto"
                      placeholder="เลือกคนขับรถ"
                    >
                      <template slot="selection" slot-scope="data">
                        {{ data.item.first_name }} {{ data.item.last_name }}
                      </template>
                      <template slot="item" slot-scope="data">
                        {{ data.item.first_name }} {{ data.item.last_name }}
                      </template>
                    </v-select>
                  </td>
                  <td class="pl-0">
                    <v-select
                      :disabled="!(item.driver_id === willEditTrip.driver_id)"
                      outlined
                      dense
                      height="32px"
                      class="rounded"
                      v-model="item.vehicle_id"
                      :items="vehicleSelect"
                      item-text="name"
                      item-value="id"
                      :rules="[rules.required_select]"
                      hide-details="auto"
                      placeholder="เลือกรถที่ไปรับของ"
                    >
                      <template slot="selection" slot-scope="data">
                        <div
                          style="
                            display: flex;
                            width: 100%;
                            justify-content: space-between;
                          "
                        >
                          <span>{{ data.item.name }}</span>
                          <span
                            ><span style="color: #265a88"
                              >ทะเบียน {{ data.item.licence }}</span
                            ></span
                          >
                        </div>
                      </template>
                      <template slot="item" slot-scope="data">
                        <div
                          style="
                            display: flex;
                            width: 100%;
                            justify-content: space-between;
                          "
                        >
                          <span>{{ data.item.name }}</span>
                          <span
                            ><span style="color: #265a88"
                              >ทะเบียน {{ data.item.licence }}</span
                            ></span
                          >
                        </div>
                      </template>
                    </v-select>
                  </td>
                  <td class="pl-0">
                    <v-checkbox
                      v-model="item.is_night"
                      :disabled="!(item.driver_id === willEditTrip.driver_id)"
                    ></v-checkbox>
                  </td>
                  <td class="pl-0">
                    <v-checkbox
                      v-model="item.trail"
                      :disabled="!(item.driver_id === willEditTrip.driver_id)"
                    ></v-checkbox>
                  </td>
                  <td class="pl-0">
                    <v-btn
                      :disabled="
                        !(
                          orderStatus === 'ใบเสนอราคา' ||
                          orderStatus === 'รับออเดอร์'
                        )
                      "
                      color="success"
                      v-if="item.driver_id === willEditTrip.driver_id"
                      @click="editTrip(item)"
                      icon
                      smaill
                    >
                      <v-icon>mdi-content-save</v-icon>
                    </v-btn>
                    <template v-else>
                      <v-btn
                        :disabled="
                          !(
                            orderStatus === 'ใบเสนอราคา' ||
                            orderStatus === 'รับออเดอร์'
                          )
                        "
                        color="primary"
                        @click="willEditTrip = item"
                        icon
                        smaill
                      >
                        <v-icon>mdi-pencil</v-icon>
                      </v-btn>
                    </template>
                  </td>
                </tr>
              </template>
            </v-data-table>
          </div>
        </pane>
        <pane min-size="20" style="overflow: auto">
          <TableSelectProduct
            v-model="orderItems"
            :for-create="false"
            :delivery-type="deliveryType"
            :seller-id="seller_id"
            :discount.sync="discountOrder"
            :count-order="trips.length"
            :update-order-props="{
              id: $route.params.id,
              isActive: orderDetail.order.isActive,
              payment_method: payment_method,
              payment_status: paymentStatus,
              order_status: orderStatus,
              delivery_date: delivery_date,
              discount: parseFloat(discountOrder),
              hasVat: orderItems.hasVat,
            }"
          />

          <v-row justify="space-between" class="mt-4 mx-0">
            <v-col align-self="center">การชำระเงิน</v-col>
            <v-col class="text-right">
              <v-select
                :disabled="
                  !(
                    orderStatus === 'ใบเสนอราคา' || orderStatus === 'รับออเดอร์' || orderStatus === 'ดำเนินการ'
                  ) ||
                  !orderDetail.order.isActive ||
                  paymentStatus === 'ชำระแล้ว'
                "
                v-model="payment_method"
                class="rounded mt-3"
                :items="paymentSelect"
                outlined
                dense
                height="32px"
                hide-details
                placeholder="เลือกรูปแบบการชำระเงิน"
              ></v-select>
            </v-col>
          </v-row>

          <v-row justify="space-between" class="mt-4 mx-0">
            <v-col align-self="center">เครดิตลูกค้า</v-col>
            <v-col class="text-right">
              {{ creditLeft || 0 }} วัน
              <span v-if="orderDetail.order.due_date"
                >(ครบกำหนด {{ orderDetail.order.due_date }})</span
              >
            </v-col>
          </v-row>

          <v-row justify="space-between" class="mt-4 mx-0">
            <v-col align-self="center">วันที่รับสินค้า</v-col>
            <v-col class="text-right">
              <DatePicker
                v-model="delivery_date"
                placeholder="เลือกวันที่"
                :min="new Date().toISOString().substr(0, 10)"
                hide-details="auto"
                :disabled="
                  !(
                    orderStatus === 'ใบเสนอราคา' || orderStatus === 'รับออเดอร์'
                  ) || !orderDetail.order.isActive
                "
              />
            </v-col>
          </v-row>

          <v-row justify="space-between" class="mt-4 mx-0">
            <v-col align-self="center">สถานะการชำระเงิน</v-col>
            <v-col
              v-if="
                paymentStatus === 'ยังไม่ชำระ' && orderDetail.order.isActive
              "
              class="text-right"
            >
              {{ paymentStatus }}
              <a @click="showConfirmPayment = true" style="font-size: 0.75rem"
                >เปลี่ยนเป็นชำระเงิน</a
              >
            </v-col>
            <v-col v-else class="text-right green--text">
              ชำระเงินแล้ว
              <span v-if="orderDetail.order.file"
                ><a :href="orderDetail.order.file" target="_blank"
                  >ดูหลักฐานการชำระเงิน</a
                ></span
              >
            </v-col>
          </v-row>

          <v-row justify="space-between" class="mt-4 mx-0">
            <v-col align-self="start"> หมายเหตุ </v-col>
            <v-col>
              <v-textarea
                hide-details
                outlined
                v-model="remark"
                rows="2"
              ></v-textarea>
            </v-col>
          </v-row>

          <v-row justify="space-between" class="mt-4 mx-0">
            <v-col align-self="center">สถานะของออเดอร์</v-col>
          </v-row>

          <v-row v-if="!orderDetail.order.isActive" class="my-4 mx-0">
            <v-col class="red--text" align-self="center"
              >ออเดอร์นี้ถูกยกเลิกแล้ว</v-col
            >
          </v-row>

          <OrderStatusTimeline
            v-else
            v-model="orderStatus"
            :for-create="false"
            :order="orderItems"
          />

          <v-row justify="start" class="my-4 mx-4">
            <v-btn
              @click="goPrintQuotation()"
              depressed
              color="blue"
              dark
              class="mr-4"
              :loading="isLoadingQuotation"
            >
              ใบเสนอราคา
            </v-btn>
            <v-btn
              v-if="
                (orderStatus === 'ดำเนินการ' || orderStatus === 'สำเร็จ') &&
                $store.state.authUser.user_permissions.includes('change_order')
              "
              color="red"
              dark
              depressed
              class="mr-4"
              :loading="isLoadingTax"
              @click="
                printLable = 'tax';
                getCustomer();
              "
            >
              ออกใบกำกับภาษี
            </v-btn>
            <v-btn
              v-if="!(orderStatus === 'ใบเสนอราคา')"
              color="success"
              @click="
                printLable = 'receipt';
                getCustomer();
              "
              depressed
              class="mr-4"
              :loading="isLoadingReceipt"
            >
              ปริ้นใบเสร็จสำหรับลูกค้า
            </v-btn>
            <v-btn
              v-if="!(orderStatus === 'ใบเสนอราคา')"
              dark
              color="pink"
              @click="printDelivery()"
              depressed
              class="mr-4"
              :loading="isLoadingDelivery"
            >
              ปริ้นใบส่งสินค้า
            </v-btn>
            <v-btn
              @click="goPrint()"
              class="amber"
              depressed
              dark
              v-if="
                !(orderStatus === 'ใบเสนอราคา' || orderStatus === 'รับออเดอร์')
              "
            >
              ปริ้นใบสำหรับพนักงาน
            </v-btn>
          </v-row>
        </pane>
      </splitpanes>
    </v-card>

    <v-card v-else class="pa-2">
      <v-row justify="space-between">
        <v-col cols="5">หมายเลข Order</v-col>
        <v-col cols="7" class="text-right">{{ year }}/{{ id_display }}</v-col>
        <v-col cols="5">ลูกค้า</v-col>
        <v-col cols="7" class="text-right">{{ customer_name }}</v-col>
        <v-col cols="5">เบอร์โทร</v-col>
        <v-col cols="7" class="text-right">{{ customer_tel }}</v-col>
        <v-col cols="5">การจัดส่ง</v-col>
        <v-col cols="7" class="text-right">
          {{ deliveryType }}
        </v-col>
        <v-col cols="5">สถานะ</v-col>
        <v-col cols="7" class="text-right">
          <v-btn :color="colorStatus(orderStatus)" outlined raised small
            >{{ orderStatus }}
          </v-btn>
        </v-col>

        <v-col cols="5">สถานะการชำระเงิน</v-col>
        <v-col cols="7" class="text-right">
          <v-btn :color="colorStatus(paymentStatus)" outlined raised small
            >{{ paymentStatus }}
          </v-btn>
        </v-col>

        <v-col cols="12">
          <v-simple-table>
            <template v-slot:default>
              <thead>
                <tr class="blue lighten-5">
                  <th class="text-left">สินค้า</th>
                  <th class="text-center">จำนวน</th>
                  <th class="text-center">จำนวน</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="item in orderItems.item" :key="item.id">
                  <td>{{ item.item_name }}</td>
                  <td class="text-center">{{ item.item_quantity }}</td>
                  <td class="text-center">{{ item.total }}</td>
                </tr>
<!--                <tr>-->
<!--                  <td>ค่าส่ง</td>-->
<!--                  <td></td>-->
<!--                  <td class="text-center">-->
<!--                    {{ orderItems.delivery.total_price }}-->
<!--                  </td>-->
<!--                </tr>-->
                <tr class="blue lighten-5">
                  <td>ราคาสุทธิ</td>
                  <td></td>
                  <td class="text-center">
                    {{ orderDetail.order.total_price }}
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-col>
        <v-col cols="5">คนตัก</v-col>
        <v-col cols="7" class="text-right">
          <v-select
            disabled
            outlined
            dense
            height="32px"
            class="rounded"
            v-model="scooper_id"
            :items="scoopSelect"
            item-text="first_name"
            item-value="id"
            :rules="[rules.required_select]"
            hide-details="auto"
            placeholder="เลือกคนขับรถ"
            return-object
          >
            <template slot="selection" slot-scope="data">
              {{ data.item.first_name }} {{ data.item.last_name }}
            </template>
            <template slot="item" slot-scope="data">
              {{ data.item.first_name }} {{ data.item.last_name }}
            </template>
          </v-select>
        </v-col>
        <v-col cols="5">เซลล์</v-col>
        <v-col cols="7" class="text-right">
          <v-select
            disabled
            clearable
            outlined
            dense
            height="32px"
            class="rounded"
            v-model="seller_id"
            :items="sellerOptions"
            item-value="id"
            hide-details="auto"
            placeholder="ไม่ได้เลือกเซลล์"
          >
            <template slot="selection" slot-scope="data">
              {{ data.item.first_name }} {{ data.item.last_name }}
            </template>
            <template slot="item" slot-scope="data">
              {{ data.item.first_name }} {{ data.item.last_name }}
            </template>
          </v-select>
        </v-col>
        <v-col cols="5">เที่ยวรถ</v-col>
        <v-col cols="7" class="text-right">{{ trips.length }} เที่ยว</v-col>
        <v-col cols="5">ลูกค้า</v-col>
        <v-col cols="7" class="text-right">{{ customer_name }}</v-col>
        <v-col cols="5">รูปแบบการชำระเงิน</v-col>
        <v-col cols="7" class="text-right">{{ payment_method }}</v-col>
        <v-col cols="5">วันที่รับสินค้า</v-col>
        <v-col cols="7" class="text-right">
          <DatePicker
            v-model="delivery_date"
            placeholder="เลือกวันที่"
            :min="new Date().toISOString().substr(0, 10)"
            hide-details="auto"
            disabled
            default-today
          />
        </v-col>
        <v-col cols="5">หมายเหตุ</v-col>
        <v-col cols="7" class="text-right">{{ remark }}</v-col>
      </v-row>
    </v-card>

    <v-dialog v-model="showConfirmPayment" persistent max-width="512">
      <v-card>
        <v-card-title style="font-family: Kanit, serif !important">
          ออเดอร์นี้ได้รับการชำระเงินครบถ้วนแล้ว
          <v-spacer></v-spacer>
          <v-btn @click="showConfirmPayment = false" icon>
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <v-form ref="proofPaymentForm">
            <v-row
              v-if="payment_method === 'ชำระภายหลัง'"
              justify="space-between"
              class="mt-4 mx-0"
            >
              <v-col class="pl-0" align-self="center">การชำระเงิน</v-col>
              <v-col cols="8" class="text-right pr-0">
                <v-select
                  v-model="newPaymentMethod"
                  class="rounded mt-3"
                  :items="newPaymentSelect"
                  outlined
                  dense
                  height="32px"
                  hide-details
                  placeholder="เลือกรูปแบบการชำระเงิน"
                ></v-select>
                <small class="red--text">
                  ออเดดอร์นี้ได้เลือกการชำระเงินภายหลัง
                  โปรดระบุที่มาของเงินใหม่</small
                >
              </v-col>
            </v-row>

            <v-file-input
              class="mt-4"
              v-model="proofPayment"
              :rules="payment_method === 'โอนเงิน' || newPaymentMethod === 'โอนเงิน' ? [rules.requiredFile] : []"
              outlined
              show-size
              placeholder="อัปโหลดหลักฐานการชำระเงิน"
            ></v-file-input>
            <p>ไฟล์ไม่เกิน 5 mb</p>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn @click="showConfirmPayment = false" text>ย้อนกลับ</v-btn>
          <v-btn @click="savePaymentStatus()" depressed color="primary"
            >ออเดอร์นี้ได้ชำระเงินแล้ว</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="showConfirmCancel" max-width="512">
      <v-card>
        <v-card-title style="font-family: Kanit, serif !important">
          คุณต้องการที่จะยกเลิกออเดอร์นี้
          <v-spacer></v-spacer>
          <v-btn @click="showConfirmCancel = false" icon>
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>หากยกเลิกออเดอร์นี้แล้วจะไม่สามารถกู้คืนได้</v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn @click="showConfirmCancel = false" text>
            ยังไม่ใช่ตอนนี้
          </v-btn>
          <v-btn @click="cancelOrder()" color="error">
            <v-icon left>mdi-delete</v-icon>
            ยกเลิกออเดอร์นี้
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="showConfirmRemoveOrder" max-width="512">
      <v-card>
        <v-card-title style="font-family: Kanit, serif !important">
          คุณต้องการที่จะลบออเดอร์นี้ออกจากระบบ
          <v-spacer></v-spacer>
          <v-btn @click="showConfirmRemoveOrder = false" icon>
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>หากยกเลิกออเดอร์นี้แล้วจะไม่สามารถกู้คืนได้</v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn @click="showConfirmRemoveOrder = false" text>
            ยังไม่ใช่ตอนนี้
          </v-btn>
          <v-btn @click="removeOrder()" color="error">
            <v-icon left>mdi-delete</v-icon>
            ยกเลิกออเดอร์นี้
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="showSelectCustomerAddress" max-width="712">
      <v-card>
        <v-card-title style="font-family: Kanit, serif !important">
          เลือกที่อยู่ (ที่อยู่อยู่นี้จะแสดงใต้ชื่อลูกค้า)
          <v-spacer></v-spacer>
          <v-btn @click="showSelectCustomerAddress = false" icon>
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <v-simple-table>
            <template v-slot:default>
              <tbody>
                <tr v-for="(item, i) in customerAddress" :key="item.name">
                  <td>{{ item.address }}</td>
                  <!--                <td>{{ item }}</td>-->
                  <td v-if="item.province">
                    {{ item.province === "กรุงเทพมหานคร" ? "แขวง" : "ตำบล"
                    }}{{ item.subdistrict }}
                    {{ item.province === "กรุงเทพมหานคร" ? "เขต" : "อำเภอ"
                    }}{{ item.district }}
                    {{
                      item.province === "กรุงเทพมหานคร"
                        ? "กรุงเทพมหานคร"
                        : `จังหวัด${item.province}`
                    }}
                    {{ item.postalCode }}
                  </td>
                  <td v-else>ไม่ได้เลือกตำบล ไม่สามารถใช้ที่อยู่นี้ได้</td>
                  <td width="40">
                    <v-btn
                      v-if="printLable === 'tax'"
                      :disabled="!item.province"
                      @click="goTaxInvoice(item.id)"
                      color="primary"
                      depressed
                    >
                      เลือกที่อยู่นี้
                    </v-btn>
                    <v-btn
                      v-else-if="printLable === 'receipt'"
                      :disabled="!item.province"
                      @click="printReceipt(item.id)"
                      color="primary"
                      depressed
                    >
                      เลือกที่อยู่นี้
                    </v-btn>
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-card-text>
        <v-divider />
        <v-card-actions v-if="printLable === 'receipt'">
          <label
            >(ถ้าข้อมูลที่อยู่ไม่ครบถ้วน ให้ข้ามการใส่ที่อยู่ของลูกค้า)</label
          >
          <v-spacer />
          <v-btn @click="printReceipt()" elevation="0" color="info"
            >X ไม่ใส่ที่อยู่ลูกค้า</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <TripDeliveryDialog
      v-model="showTripDeliveryDialog"
      :order-items="orderItems.item"
      :trip="current_trip"
      @close="
        showTripDeliveryDialog = false;
        current_trip = null;
      "
      @clear-trip="current_trip = null"
    />
  </div>
</template>

<script>
import axios from "axios";
import TableSelectProduct from "@/components/TableSelectProduct";
import OrderStatusTimeline from "@/components/OrderStatusTimeline";
import DatePicker from "@/components/DatePicker";
import Map from "@/components/Map";

import { Splitpanes, Pane } from "splitpanes";
import "splitpanes/dist/splitpanes.css";
import store from "@/store";
import TripDeliveryDialog from "@/components/TripDeliveryDialog";

export default {
  components: {
    TripDeliveryDialog,
    TableSelectProduct,
    OrderStatusTimeline,
    DatePicker,
    Map,
    Splitpanes,
    Pane,
  },
  name: "OrderDetail",
  data() {
    return {
      rules: {
        required: (value) => !!value || "ต้องกรอก",
        requiredFile: (value) => !!value || "ต้องอัปโหลด Slip โอนเงิน",
        required_select: (value) => !!value || "ต้องเลือก",
        min: (value) => value > 0 || "จำนวนต้องเป็นตั้งแต่ 1 ขึ้นไป",
      },
      id_display: "",
      customer_name: "",
      customer_tel: "",
      customer_address: "",
      deliveryType: "",
      delivery_date: "",
      searchAddress: "",
      orderStatus: null,
      paymentStatus: "",
      distance: 0,
      creditLeft: 0,
      trips: [],
      receive_date: "",
      orderItems: {
        item: [],
        delivery: {
          price: 0,
          amount: 1,
          total_price: 0,
          discount: 0,
          commission_rate: 0,
        },
        hasVat: false,
        netPrice: 0,
      },
      paymentSelect: [
        {
          text: "เงินสด",
          value: "เงินสด",
        },
        {
          text: "โอนเงิน",
          value: "โอนเงิน",
        },
        {
          text: "บัตรเครดิต",
          value: "บัตรเครดิต",
        },
        {
          text: "ชำระภายหลัง",
          value: "ชำระภายหลัง",
        },
        {
          text: "ตัดเงินเติม",
          value: "ตัดเงินเติม",
        },
      ],
      newPaymentSelect: [
        {
          text: "เงินสด",
          value: "เงินสด",
        },
        {
          text: "โอนเงิน",
          value: "โอนเงิน",
        },
        {
          text: "บัตรเครดิต",
          value: "บัตรเครดิต",
        },
      ],
      payment_method: "",
      scoopSelect: [],
      scooper_id: 0,
      vehicleSelect: [],
      driverSelect: [],
      deliveryTypeSelect: [
        {
          text: "ออกไปส่ง",
          value: "ออกไปส่ง",
        },
        {
          text: "จ้างรถข้างนอก",
          value: "จ้างรถข้างนอก",
        },
        {
          text: "ลูกค้ามารับ",
          value: "ลูกค้ามารับ",
        },
      ],
      discountOrder: 0,
      latLng: {
        lat: null,
        lng: null,
      },
      showConfirmPayment: false,
      showConfirmCancel: false,
      proofPayment: "",
      remark: "",
      tripHeader: [
        {
          text: "เที่ยว",
          value: "no",
          width: 80,
          class: "pl-0",
        },
        {
          text: "คนขับ",
          value: "driver_id",
        },
        {
          text: "รถ",
          value: "car_license",
        },
        {
          text: "กลางคืน",
          value: "is_night",
        },
        {
          text: "พ่วง",
          value: "trail",
        },
        {
          text: "",
          value: "actions",
          width: "120",
        },
      ],
      willEditTrip: [],
      seller_id: null,
      sellerOptions: [],
      orderDetail: {},
      newPaymentMethod: "",
      queryString: "",
      showSelectCustomerAddress: false,
      customerAddress: [],
      isLoadingQuotation: false,
      isLoadingReceipt: false,
      isLoadingDelivery: false,
      isLoadingTax: false,
      year: "",
      client_refer: "",
      linkGoogleMap: "",
      showConfirmRemoveOrder: false,
      printLable: "",
      showTripDeliveryDialog: false,
      current_trip: null,
    };
  },
  created() {
    this.getScoop();
    this.getDriver();
    this.getVehicle();
    this.getOrderDetail();
    this.getSeller();
  },
  methods: {
    yearBeforeOrder() {
      if (this.id_display.split("/").length > 1) {
        return this.id_display.split("/")[0];
      } else {
        return "";
      }
    },
    isMobile() {
      return /Android|webOS|iPhone|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
        navigator.userAgent
      );
    },
    colorStatus(status) {
      switch (status) {
        case "รับออเดอร์":
          return "primary";
        case "ใบเสนอราคา":
          return "orange";
        case "ดำเนินการ":
          return "amber";
        case "สำเร็จ":
          return "green";
        case "ชำระแล้ว":
          return "green";
        case "ยังไม่ชำระ":
          return "error";
      }
    },
    getSeller() {
      axios.get("/api/stocks/seller/").then((res) => {
        this.sellerOptions = res.data.data;
      });
    },
    goPrint() {
      // let objItem = JSON.stringify(this.orderItems)
      let route = this.$router.resolve({
        path: "/print",
        query: {
          name: "order_scoop_driver",
          order_id: this.$route.params.id,
        },
      });
      console.log(this.orderDetail);
      window.open(route.href, "_blank");
    },
    goPrintQuotation() {
      window.open(
        `${this.$store.state.endpoints.baseUrL}/api/orders/quotation/${this.$route.params.id}/`,
        "_blank"
      );
    },
    printReceipt(addressId = null) {
      this.showSelectCustomerAddress = false;
      let address_str = addressId !== null ? `?address_id=${addressId}` : "";
      window.open(
        `${this.$store.state.endpoints.baseUrL}/api/orders/receipt/${this.$route.params.id}/` +
          address_str,
        "_blank"
      );
    },
    printDelivery() {
      window.open(
        `${this.$store.state.endpoints.baseUrL}/api/orders/delivery/${this.$route.params.id}/`,
        "_blank"
      );
    },
    goTaxInvoice(addressId) {
      this.showSelectCustomerAddress = false;
      window.open(
        `${this.$store.state.endpoints.baseUrL}/api/orders/tax_invoice/${this.$route.params.id}/?address_id=${addressId}`,
        "_blank"
      );
    },
    getDriver() {
      axios
        .get("/api/stocks/driver/")
        .then((res) => {
          this.driverSelect = res.data.data;
        })
        .catch((e) => {
          this.$store.state.snackbar = {
            text:
              "เกิดข้อผิดพลาด กรุณาลองใหม่อีกครั้ง ข้อความที่ระบบแจ้ง : " +
              e.response.status +
              " " +
              e.response.statusText,
            visible: true,
            color: "error",
          };
        });
    },
    getVehicle() {
      axios
        .get("/api/stocks/vehicle/รถบรรทุก/")
        .then((res) => {
          this.vehicleSelect = res.data.data;
        })
        .catch((e) => {
          this.$store.state.snackbar = {
            text:
              "เกิดข้อผิดพลาด กรุณาลองใหม่อีกครั้ง ข้อความที่ระบบแจ้ง : " +
              e.response.status +
              " " +
              e.response.statusText,
            visible: true,
            color: "error",
          };
        });
    },
    getScoop() {
      axios
        .get("/api/stocks/driver/")
        .then((res) => {
          this.scoopSelect = res.data.data;
          // console.log(res.data.data)
        })
        .catch((e) => {
          this.$store.state.snackbar = {
            text:
              "เกิดข้อผิดพลาด กรุณาลองใหม่อีกครั้ง ข้อความที่ระบบแจ้ง : " +
              e.response.status +
              " " +
              e.response.statusText,
            visible: true,
            color: "error",
          };
        });
    },
    getDistance(value) {
      // this.distance = value.distance
      // this.latlngSelect = value.latlng
      // this.delivery_rate.forEach(d=>{
      //   if(this.distance > d.distance){
      //     console.log('than')
      //   }
      // })
    },
    getOrderDetail() {
      axios
        .get(`/api/orders/${this.$route.params.id}/`)
        .then((res) => {
          this.orderDetail = res.data.data;
          this.client_refer = res.data.data.order.client_refer;
          this.customer_name = res.data.data.order.customer_name;
          // this.customer_tel = res.data.data.order.customer_name
          this.customer_address = res.data.data.order.customer_address;

          this.year = res.data.data.order.id_display.split("/")[0];
          this.id_display = res.data.data.order.id_display.split("/")[1];

          res.data.data.order_item.forEach((i) => {
            if (i.name === "ค่าส่งงงงงงงงงงงงงง") {
              this.orderItems.delivery = {
                id: i.id,
                amount: parseFloat(i.quantity),
                discount: parseFloat(i.discount),
                total_price: parseFloat(i.total_price),
                price: parseFloat(i.price_per_unit),
                commission_rate: parseFloat(i.commission_rate),
              };
            } else {
              this.orderItems.item.push({
                id: i.id,
                product_id: i.product,
                item_name: i.name,
                item_price_per_unit: parseFloat(i.price_per_unit),
                item_quantity: parseFloat(i.quantity),
                item_total:
                  parseFloat(i.price_per_unit) * parseFloat(i.quantity),
                discount: parseFloat(i.discount),
                total:
                  parseFloat(i.price_per_unit) * parseFloat(i.quantity) -
                  parseFloat(i.discount),
                category: "product",
                commission_rate: i.commission_rate,
                stock: i.stock_location,
                unit: i.unit,
                cost: i.cost,
              });
            }
          });

          this.discountOrder = res.data.data.order.discount;

          this.distance = res.data.data.order.distance;
          this.scooper_id = res.data.data.order.scooper;
          this.seller_id = res.data.data.order.seller
            ? res.data.data.order.seller.id
            : null;
          this.customer_tel = res.data.data.order.customer.tel;
          res.data.data.trip.forEach((t) => {
            this.trips.push({
              ...t,
              id: t.id,
              driver_id: t.driver.id,
              vehicle_id: t.vehicle.id,
              is_night: t.at_night,
              trail: t.trail,
              order_item: t.triporderitem_set || [],
            });
          });
          this.trips = this.trips.sort((a, b) => (a.id < b.id ? -1 : 1));
          this.deliveryType = res.data.data.order.delivery_type;
          this.delivery_date = res.data.data.order.delivery_date;
          this.payment_method = res.data.data.order.payment_method;
          this.newPaymentMethod = res.data.data.order.payment_method;
          this.orderItems.hasVat = res.data.data.order.vat;
          this.paymentStatus = res.data.data.order.payment_status;
          if (res.data.data.order.latitude !== null) {
            this.latLng = {
              lat: parseFloat(res.data.data.order.latitude),
              lng: parseFloat(res.data.data.order.longitude),
            };
          }

          this.orderStatus = res.data.data.order.order_status;
          this.remark = res.data.data.order.remark;
          this.$store.state.isLoading = false;
        })
        .catch((e) => {
          this.$store.state.snackbar = {
            text:
              "เกิดข้อผิดพลาด กรุณาลองใหม่อีกครั้ง ข้อความที่ระบบแจ้ง : " +
              e.response.status +
              " " +
              e.response.statusText,
            visible: true,
            color: "error",
          };
        });
    },
    getCustomer() {
      this.showSelectCustomerAddress = true;
      axios
        .get(`/api/accounts/customer/${this.orderDetail.order.customer.id}/`)
        .then((res) => {
          this.customerAddress = res.data.data.customer_address;
        })
        .catch((e) => {
          this.$store.state.snackbar = {
            text:
              "เกิดข้อผิดพลาด กรุณาลองใหม่อีกครั้ง ข้อความที่ระบบแจ้ง : " +
              e.response.status +
              " " +
              e.response.statusText,
            visible: true,
            color: "error",
          };
        });
    },
    editTrip(item) {
      this.willEditTrip = {};
      let payload = {
        id: item.id,
        driver_id: item.driver_id,
        vehicle_id: item.vehicle_id,
        at_night: item.is_night,
        trail: item.trail,
        round: 1,
      };
      axios
        .put("/api/stocks/trip/", payload)
        .then(() => {
          this.$store.state.snackbar = {
            text: "บันทึกการแก้ไขเที่ยวรถสำเร็จ",
            visible: true,
            color: "success",
          };
        })
        .catch((e) => {
          this.$store.state.snackbar = {
            text:
              "เกิดข้อผิดพลาด กรุณาลองใหม่อีกครั้ง ข้อความที่ระบบแจ้ง Trips : " +
              e.response.status +
              " " +
              e.response.statusText,
            visible: true,
            color: "error",
          };
        });
    },
    cancelOrder() {
      axios
        .delete(`/api/orders/${this.$route.params.id}/`)
        .then((res) => {
          console.log(res.data.data);
          this.$store.state.snackbar = {
            text: "ออเดอร์ถูกยกเลิกสำเร็จ",
            visible: true,
            color: "success",
          };
          this.$router.push("/order");
        })
        .catch((e) => {
          this.$store.state.snackbar = {
            text:
              "เกิดข้อผิดพลาด กรุณาลองใหม่อีกครั้ง ข้อความที่ระบบแจ้ง : " +
              e.response.status +
              " " +
              e.response.statusText,
            visible: true,
            color: "error",
          };
        });
    },
    removeOrder() {
      axios
        .delete(`/api/orders/delete_pemanent/${this.$route.params.id}/`)
        .then((res) => {
          console.log(res.data.data);
          this.$store.state.snackbar = {
            text: "ออเดอร์ถูกลบออกจากระบบเรียบร้อย",
            visible: true,
            color: "success",
          };
          this.$router.push("/order");
        })
        .catch((e) => {
          this.$store.state.snackbar = {
            text:
              "เกิดข้อผิดพลาด กรุณาลองใหม่อีกครั้ง ข้อความที่ระบบแจ้ง : Remove Deleted Order" +
              e.response.status +
              " " +
              e.response.statusText,
            visible: true,
            color: "error",
          };
        });
    },
    savePaymentStatus() {
      if (this.$refs.proofPaymentForm.validate()) {
        let payload = {
          id: this.$route.params.id,
          payment_status: "ชำระแล้ว",
          payment_method: this.newPaymentMethod,
        };
        axios
          .put("/api/orders/", payload)
          .then(() => {
            if (this.proofPayment) {
              let formData = new FormData();
              formData.append("file", this.proofPayment);
              formData.append("order_id", this.$route.params.id);
              axios
                .post("/api/orders/file/", formData)
                .then((res) => {
                  console.log(res.data.data);
                  this.showConfirmPayment = false;
                  this.$store.state.snackbar = {
                    text: `ออเดอร์นี้ชำระเงินแล้ว`,
                    visible: true,
                    color: "success",
                  };
                  window.location.reload();
                })
                .catch((e) => {
                  this.$store.state.snackbar = {
                    text:
                      "เกิดข้อผิดพลาด กรุณาลองใหม่อีกครั้ง ข้อความที่ระบบแจ้ง Error at File : " +
                      e.response.status +
                      " " +
                      e.response.statusText,
                    visible: true,
                    color: "error",
                  };
                });
            } else {
              this.showConfirmPayment = false;
              this.$store.state.snackbar = {
                text: `ออเดอร์นี้ชำระเงินแล้ว`,
                visible: true,
                color: "success",
              };
              window.location.reload();
            }
            // window.location.reload();
          })
          .catch((e) => {
            this.$store.state.snackbar = {
              text:
                "เกิดข้อผิดพลาด กรุณาลองใหม่อีกครั้ง ข้อความที่ระบบแจ้ง Order : " +
                e.response.status +
                " " +
                e.response.statusText,
              visible: true,
              color: "error",
            };
          });
      }
    },
    saveEditOrder() {
      let payload = {
        id: this.$route.params.id,
        scooper_id: this.scooper_id.id,
        payment_method: this.payment_method,
        delivery_date: this.delivery_date,
        remark: this.remark,
        discount: this.discountOrder,
        hasVat: this.orderItems.hasVat,
        client_refer: this.client_refer,
        // id_display: `${this.year}/${this.id_display}`,
        seller_id: this.seller_id,
      };
      if (
        this.orderStatus !== "ใบเสนอราคา" &&
        this.orderDetail.order.isActive === true
      ) {
        payload.id_display = `${this.year}/${this.id_display}`;
      }
      console.log(payload, this.orderDetail.order);
      axios
        .put("/api/orders/", payload)
        .then((res) => {
          console.log(res.data.data);
          this.newPaymentMethod = this.payment_method;
          this.$store.state.snackbar = {
            text: `บันทึกการแก้ไขในออเดอร์นี้แล้ว`,
            visible: true,
            color: "success",
          };
          // window.location.reload();
        })
        .catch((e) => {
          this.$store.state.snackbar = {
            text:
              "เกิดข้อผิดพลาด กรุณาลองใหม่อีกครั้ง ข้อความที่ระบบแจ้ง : " +
              e.response.status +
              " " +
              e.response.statusText,
            visible: true,
            color: "error",
          };
        });
    },
  },
};
</script>

<style scoped>
.splitpanes >>> .splitpanes__splitter {
  position: relative;
  width: 5px;
  min-width: 5px;
  background-color: #d3d3d3;
}

.v-expansion-panel-content >>> .v-expansion-panel-content__wrap {
  padding: 0 !important;
}
</style>
