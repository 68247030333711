var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"d-flex justify-space-between mx-3 mt-4"},[_c('div',{staticClass:"box-stepper",on:{"click":function($event){return _vm.handleInput(0)}}},[_c('div',{staticClass:"stepper activated-dot"}),_c('p',[_vm._v("ใบเสนอราคา")])]),_c('hr',{style:({ borderColor: _vm.stepNumber >= 1 ? '#6E7F94' : '#e0e0e0' })}),_c('div',{staticClass:"box-stepper",on:{"click":function($event){return _vm.handleInput(1)}}},[_c('div',{staticClass:"stepper",style:({ backgroundColor: _vm.stepNumber >= 1 ? '#6E7F94' : '#e0e0e0' })}),_c('p',[_vm._v("รับออเดอร์")])]),_c('hr',{style:({ borderColor: _vm.stepNumber >= 2 ? '#6E7F94' : '#e0e0e0' })}),_c('div',{staticClass:"box-stepper",on:{"click":function($event){return _vm.handleInput(2)}}},[_c('div',{staticClass:"stepper",style:({ backgroundColor: _vm.stepNumber >= 2 ? '#6E7F94' : '#e0e0e0' })}),_c('p',[_vm._v("ดำเนินการ")])]),_c('hr',{style:({ borderColor: _vm.stepNumber >= 3 ? '#6E7F94' : '#e0e0e0' })}),_c('div',{staticClass:"box-stepper",on:{"click":function($event){return _vm.handleInput(3)}}},[_c('div',{staticClass:"stepper",style:({ backgroundColor: _vm.stepNumber >= 3 ? '#6E7F94' : '#e0e0e0' })}),_c('p',[_vm._v("รับสินค้าแล้ว")])])]),_c('v-dialog',{attrs:{"max-width":"500px","persistent":""},model:{value:(_vm.showConfirmChangeStatus),callback:function ($$v) {_vm.showConfirmChangeStatus=$$v},expression:"showConfirmChangeStatus"}},[_c('v-card',[_c('v-card-title',[_c('span',[_vm._v("เปลี่ยนเป็นรับ Order")]),_c('v-spacer')],1),_c('v-card-text',[_vm._v(" คุณยืนยันที่รับออเดอร์? ")]),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"text":""},on:{"click":function($event){_vm.showConfirmChangeStatus = false;
            _vm.$emit('input', _vm.stepText[_vm.currentIndex]);}}},[_vm._v(" ยังไม่ใช่ตอนนี้ ")]),_c('v-btn',{attrs:{"color":"primary"},on:{"click":function($event){_vm.showConfirmChangeStatus = false;
            _vm.changeToOrder();}}},[_vm._v(" เปลี่ยนเป็นรับออเดอร์ ")])],1)],1)],1),(!_vm.forCreate)?_c('v-dialog',{attrs:{"max-width":"648px","persistent":""},model:{value:(_vm.showSelectStock),callback:function ($$v) {_vm.showSelectStock=$$v},expression:"showSelectStock"}},[(_vm.productStock.length > 0)?_c('v-stepper',{model:{value:(_vm.stepper),callback:function ($$v) {_vm.stepper=$$v},expression:"stepper"}},[_c('v-stepper-items',_vm._l((_vm.productStock),function(item,index){return _c('v-stepper-content',{key:item.id,attrs:{"step":index + 1}},[_c('v-card',{staticClass:"mb-4",attrs:{"height":"280"}},[_c('v-card-title',{staticClass:"px-0 pt-0 mb-4 d-flex justify-space-between",staticStyle:{"font-size":"1.75rem"}},[_c('span',[_vm._v("เลือก Stock ของ "+_vm._s(item.item_name)),_c('span',{staticClass:"orange--text mx-2"})]),_c('v-icon',{on:{"click":function($event){_vm.showSelectStock = false;
                  _vm.$emit('input', _vm.stepText[_vm.currentIndex]);}}},[_vm._v("mdi-close")])],1),_c('v-card-text',{staticClass:"px-0"},[_c('p',{staticStyle:{"font-size":"1.155rem"}},[_vm._v(" จำนวน\""+_vm._s(item.item_name)+"\"ที่เลือกไว้ "),_c('b',{staticClass:"red--text"},[_vm._v(_vm._s(item.item_quantity))]),_vm._v(" "+_vm._s(item.unit)+" ")]),_vm._l((item.stock),function(stock,index){return _c('v-row',{key:stock.id},[_c('v-col',[_vm._v(_vm._s(stock.location)+" "),_c('span',{staticClass:"blue--text ml-2"},[_vm._v("(เหลือ "+_vm._s(stock.amount)+")")])]),_c('v-col',[_c('v-text-field',{attrs:{"disabled":item.stock.length === 1,"rules":[_vm.rules.min],"min":"0","outlined":"","hide-details":"","type":"number"},model:{value:(stock.cut),callback:function ($$v) {_vm.$set(stock, "cut", $$v)},expression:"stock.cut"}})],1)],1)})],2),_c('v-card-actions',[_c('v-spacer'),(index + 1 > 1)?_c('v-btn',{attrs:{"text":""},on:{"click":function($event){_vm.stepper--}}},[_vm._v(" ย้อนกลับ ")]):_vm._e(),(index + 1 < _vm.productStock.length)?_c('v-btn',{attrs:{"color":"primary"},on:{"click":function($event){_vm.stepper++}}},[_vm._v(" ต่อไป ")]):_c('v-btn',{attrs:{"color":"primary"},on:{"click":function($event){_vm.showSelectStock = false;
                  _vm.changeToOperation();}}},[_vm._v(" ดำเนินการ ")])],1)],1)],1)}),1)],1):_c('v-card',[_c('v-card-title',[_vm._v(" เปลี่ยนเป็นสถานะดำเนินการ ")]),_c('v-card-text',[_vm._v(" รายการสินค้าที่อยู่ในออเดอร์จะไม่สามารถเปลี่ยนแปลงได้ ")]),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"text":""},on:{"click":function($event){_vm.showSelectStock = false}}},[_vm._v(" ยังไม่ใช่ตอนนี้ ")]),_c('v-btn',{attrs:{"color":"primary"},on:{"click":function($event){_vm.showSelectStock = false;
            _vm.changeToOperation();}}},[_vm._v(" ดำเนินการ ")])],1)],1)],1):_vm._e(),_c('v-dialog',{attrs:{"max-width":"500px","persistent":""},model:{value:(_vm.showConfirmCompleteOrder),callback:function ($$v) {_vm.showConfirmCompleteOrder=$$v},expression:"showConfirmCompleteOrder"}},[_c('v-card',[_c('v-card-title',[_c('span',[_vm._v("ออเดอร์นี้ได้รับสินค้าแล้ว")]),_c('v-spacer')],1),_c('v-card-text',[_vm._v(" โปรดตรวจสอบข้อมูลให้ถูกต้อง หากบันทึกข้อมูลไปแล้วจะไม่สามารถย้อนกลับขั้นตอนได้ ")]),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"text":""},on:{"click":function($event){_vm.showConfirmCompleteOrder = false;
            _vm.$emit('input', _vm.stepText[_vm.currentIndex]);}}},[_vm._v(" ยังไม่ใช่ตอนนี้ ")]),_c('v-btn',{attrs:{"color":"primary"},on:{"click":function($event){_vm.showConfirmCompleteOrder = false;
            _vm.changeToComplete();}}},[_vm._v(" ออเดอร์นี้ได้รับสินค้าเรียบร้อยแล้ว ")])],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }